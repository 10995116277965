import { Moment } from 'moment';

import { Injectable } from '@angular/core';
import {
    IGetStringOptions, LocalizationServiceBase, LocalizationServiceInjected
} from '@profis-engineering/pe-ui-common/services/localization.common';

import { environment } from '../../environments/environmentC2C';

type Translations = Partial<Record<string, string>>;
type LanguageTranslations = Partial<Record<string, Translations>>;

@Injectable({
    providedIn: 'root'
})
export class LocalizationService extends LocalizationServiceInjected {

    private translations?: Translations;
    private missingKeys: Record<string, undefined> = {};

    private translationsLoaded = false;
    private translationsLoading = false;

    private languageTranslations: LanguageTranslations = {};

    public override setBaseService(baseService: LocalizationServiceBase): void {
        this.baseService = baseService;

        this.hasTranslation = baseService.hasTranslation.bind(baseService);
        this.sanitizeText = baseService.sanitizeText.bind(baseService);
        this.numberFormat = baseService.numberFormat.bind(baseService);
        this.isHtml = baseService.isHtml.bind(baseService);
        this.moment = baseService.moment.bind(baseService);
        this.selectTranslations = baseService.selectTranslations.bind(baseService);
        this.getLocalizedStringByCulture = baseService.getLocalizedStringByCulture.bind(baseService);
        this.baseService.addGetTranslationsHook(this.loadTranslations.bind(this));
    }

    public get initialized(): boolean {
        return this.translationsLoaded;
    }

    public get loading(): boolean {
        return this.translationsLoading;
    }

    public async fetchTranslations(name: string): Promise<Translations | undefined> {
        const urlName = environment.manifest != null
            ? environment.manifest[name]
            : name;

        // return undefined for missing translations
        // undefined return will default to en-us language
        if (urlName == null) {
            console.warn(`Translation manifest for ${name} not found`);
            return undefined;
        }

        const response = await fetch(`cdn/pe-ui-c2c/translations/${urlName}`, {
            method: 'GET',
            // no-cache in local debug where we don't build the manifest file
            cache: environment.manifest != null ? undefined : 'no-cache'
        });

        // we have translations
        if (response.ok) {
            return (await response.json()) ?? {};
        }

        // missing translations
        if (response.status == 404) {
            return undefined;
        }

        // error
        throw new Error('loadTranslations error response', { cause: response });
    }

    public async loadTranslations(language = this.selectedLanguage): Promise<void> {
        this.translationsLoading = true;

        // translations might already be loaded from before
        const currentTranslations = this.languageTranslations[language];
        if (currentTranslations != null) {
            this.translations = currentTranslations;
            return;
        }

        // load translations
        try {
            const translations = await this.fetchTranslations(`translation_${language}.json`);

            // missing translations, try en-US
            if (translations == null) {
                if (language != 'en-US') {
                    await this.loadTranslations('en-US');
                    return;
                }

                // even en-US is missing
                // clear all translations so we get keys as values
                this.translations = {};
                return;
            }

            // save translations
            this.languageTranslations[language] = this.translations = translations;
            this.translationsLoaded = true;
            const len = Object.entries(translations).length;
            console.log(`Translations loaded for ${language} (${len} keys)`);
        }
        catch (error) {
            console.error(error);

            // clear all translations so we get keys as values
            this.translations = {};
            this.translationsLoaded = false;
            return;
        }
        finally {
            this.translationsLoading = false;
        }
    }

    /** Get translation for translation key.*/
    public override getString(key: string, opts?: IGetStringOptions | undefined): string {
        if (key == null) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return undefined!;
        }

        const translation = this.translations?.[key] ?? this.baseService.getString(key, { optional: true });

        if (translation == null) {
            if (opts?.optional) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return undefined!;
            }

            if (!(key in this.missingKeys)) {
                this.missingKeys[key] = undefined;
                console.warn(`Missing localized string: ${key}`);
            }

            return `#?${key}?#`;
        }

        if (opts?.tags != null) {
            return this.sanitizeText(translation, opts.tags);
        }

        return translation;
    }

    public override moment(date?: Date): Moment {
        return this.baseService.moment(date);
    }

    public override getKeyExists(_key: string): boolean {
        return !!(this.translations?.[_key] ?? this.baseService.getKeyExists(_key));
    }
}
