import { Injectable, NgZone } from '@angular/core';
import {
    CommonRegion as Region
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Design, DesignEvent, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DisplayDesignType, IDetailedDisplayDesign as IDetailedDisplayDesignCommon
} from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    AppSettings, IDesignListInfo, IDesignManagement, IFavoritesInfo, IQuickStartApplication, ITrackingInfo,
    IUserSettingsInfo, UserSettingsSection
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import {
    ApplicationSettingsDisplayType, ApplicationState, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { normalizeArray } from '@profis-engineering/pe-ui-common/helpers/array-helper';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { getModuleOrder } from '@profis-engineering/pe-ui-common/helpers/module-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    CantOpenDesignBecauseLockedByOtherUser, DesignExternalMetaData as DesignExternalMetaDataCommon,
    DocumentAccessMode, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';
import { environment } from '../../environments/environmentC2C';
import { IC2CFixing } from '../../shared/entities/c2c-fixing';
import {
    ApplicationType as ApplicationTypeC2C
} from '../../shared/entities/code-lists/application-type';
import {
    ConnectionType as ConnectionTypeC2C
} from '../../shared/entities/code-lists/connection-type';
import {
    MethodGroupConnTypeStandardRegion
} from '../../shared/entities/code-lists/connection-type-method-standard-regions';
import { DesignType } from '../../shared/entities/code-lists/design-type';
import { DesignC2C, DesignExternalMetaDataC2C } from '../../shared/entities/design-c2c';
import {
    convertStringToLatestDesign, detailedDisplayDesignToProjectDesignC2C, IDetailedDisplayDesign
} from '../../shared/entities/display-design';
import { DesignType as DesignTypeId } from '../../shared/entities/tracking-data';
import { QuickStartSettings, UserSettingsC2C } from '../../shared/entities/user-settings';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    NewDesignDataEntityC2C, ProjectDesignEntityC2C, UIProperty
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, ConnectionType, DesignStandard as DesignStandardEnum, MaximumAreaReinforcement,
    MetricEquation, MinimumAreaReinforcement, OptimizationStrategy, ReportPaperSize, ReportType,
    SpacingMaximum, StrutTies
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    getAvailableConnectionTypesForRegionC2C, isOnlyOverlay
} from '../../shared/helpers/connection-type-helper';
import { defaultUiPropertyValue } from '../../shared/helpers/ui-property-helpers';
import { PropertyMetaDataC2C } from '../../shared/properties/properties';
import { Constants } from '../entities/constants';
import { getMetaDataFromDesign } from '../../shared/entities/design-external-metadata';
import { IDesignInfo } from '../entities/module-initial-data';
import { AppSettingsHelperC2C } from '../helpers/app-settings-helper';
import { DesignMethodGroupHelper } from '../helpers/design-method-group-helper';
import { getSpriteAsIconStyle } from '../sprites';
import { AppSettingsService } from './app-settings.service';
import { CalculationServiceC2C } from './calculation-c2c.service';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { DesignTemplateService } from './design-template.service';
import { DesignService } from './design.service';
import { DocumentServiceC2C } from './document.service';
import { FavoritesService } from './favorites.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { NumberService } from './number.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { TrackingService } from './tracking.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationProviderService {
    private _designStandardId?: number;

    private designTypes?: { [id: string]: DesignType };
    private readonly appSettingsHelper: AppSettingsHelperC2C;

    constructor(
        private readonly featuresVisibilityService: FeaturesVisibilityService,
        private readonly localization: LocalizationService,
        private readonly numberService: NumberService,
        private readonly codeList: CodeListService,
        private readonly commonCodeList: CommonCodeListService,
        private readonly userSettingsService: UserSettingsService,
        private readonly appSettings: AppSettingsService,
        private readonly calculationService: CalculationServiceC2C,
        private readonly designService: DesignService,
        private readonly sharedEnvironmentService: SharedEnvironmentService,
        private readonly modalService: ModalService,
        private readonly favoritesService: FavoritesService,
        private readonly ngZone: NgZone,
        private readonly documentService: DocumentServiceC2C,
        private readonly designTemplateService: DesignTemplateService,
        private readonly trackingService: TrackingService
    ) {
        this.appSettingsHelper = new AppSettingsHelperC2C(this.localization, this.userSettingsService, this.commonCodeList, this.codeList, this.numberService);
    }

    public set designStandardId(designStandardId: number | undefined) {
        this._designStandardId = designStandardId;
    }

    public get designStandardId() {
        return this._designStandardId;
    }

    public get getDesignTypes() {
        return this.codeList.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C];
    }

    public get c2cOverlayDemoOnly(): boolean {
        return environment.c2cOverlayDemoEnabled;
    }

    public get allowConcrete2ConcretePirQuickStart() {
        let pirQuickStartAllowed = true;

        if (environment.c2cForceAvailableCountriesEnabled) {
            let regionId = this.userSettingsService.settings.quickStart.concrete2Concrete.generalRegionId.value;
            if (regionId == null || regionId == SpecialRegion.Default) {
                regionId = this.userSettingsService.settings.application.general.regionId.value;
            }

            pirQuickStartAllowed = regionId != null ? environment.c2cForceAvailableCountriesPir.includes(regionId) : false;
        }
        return pirQuickStartAllowed && this.allowConcrete2ConcreteQuickStart;
    }

    public get isOverlayEnabled() {
        return this.connectionTypeC2C.includes(ConnectionType.ConcreteOverlay) ?? false;
    }

    public get isEntryPointSplitted() {
        const quickStartSettingsC2C = this.userSettingsService.quickStartConcrete2ConcreteSettings;
        const regionId = quickStartSettingsC2C.general_region?.id != null && quickStartSettingsC2C.general_region.id > 0
            ? quickStartSettingsC2C.general_region.id
            : this.userSettingsService.settings.application.general.regionId.value;

        if (regionId == null)
            return false;

        const designStandardId = quickStartSettingsC2C.calculation_designStandardC2C.id ?? this.codeList.getPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignStandard.id, regionId, 0).defaultValue;
        return this.appSettingsHelper.isEntryPointSplitted(regionId, designStandardId) ?? false;
    }

    public get overlayOnlyC2C() {
        const quickStartConcrete2ConcreteSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;

        const regionId = quickStartConcrete2ConcreteSettings.general_region?.id != null && quickStartConcrete2ConcreteSettings.general_region.id > 0
            ? quickStartConcrete2ConcreteSettings.general_region.id
            : this.userSettingsService.settings.application.general.regionId.value;

        if (regionId == null)
            return false;

        return isOnlyOverlay(this.codeList, regionId);
    }

    public get allowConcrete2ConcreteQuickStart() {
        if (!environment.c2cEnabled && !environment.c2cOverlayDemoEnabled) {
            return false;
        }

        if (this.userSettingsService.settings.quickStart.concrete2Concrete.generalRegionId.value == null ||
            this.userSettingsService.settings.quickStart.concrete2Concrete.generalRegionId.value == SpecialRegion.Default) {
            return this.allowConcrete2Concrete;
        }

        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Concrete2Concrete];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.quickStart.concrete2Concrete.generalRegionId.value) ?? false;
    }

    public get allowConcrete2Concrete() {
        if (this.designTypes == null)
            return false;

        const designType = this.designTypes[DesignTypeId.Concrete2Concrete];
        return designType.allowedDesignStandardRegions?.some(x => x.RegionId == this.userSettingsService.settings.application.general.regionId.value) ?? false;
    }

    public get IsNewHomePageEnabled(): boolean {
        return this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }

    private get connectionTypeC2C() {
        const quickStartConcrete2ConcreteSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;
        const regionId = quickStartConcrete2ConcreteSettings.general_region?.id != null && quickStartConcrete2ConcreteSettings.general_region.id > 0
            ? quickStartConcrete2ConcreteSettings.general_region.id
            : this.userSettingsService.settings.application.general.regionId.value;

        if (regionId == null)
            return [];

        return getAvailableConnectionTypesForRegionC2C(this.codeList, regionId);
    }


    /** This is a temporary method to update design type translations (present on UI where module designs are listed).
     * We should remove this after proper support for this is added to common code (pe-ui)*/
    public updateDesignTypeTranslations() {
        this.getDesignTypes.forEach(designType => { designType.name = this.localization.getString(designType.nameResourceKey ?? ''); });
    }

    public concrete2ConcreteDesignInfo(designName?: string) : IDesignInfo {
        return {
            order: getModuleOrder(Constants.ModuleIndex, 0),
            id: 'add-edit-design-image-container-concrete2concrete',
            designTypeId: DesignTypeId.Concrete2Concrete,
            connectionType: [ConnectionType.ConcreteOverlay, ConnectionType.Splices, ConnectionType.StructuralJoints],
            title: this.translate('Agito.Hilti.Profis3.AddEditDesign.Type.Concrete2Concrete'),
            titleTranslationKey: 'Agito.Hilti.Profis3.AddEditDesign.Type.Concrete2Concrete',
            mainTagName: 'c2c-main',
            componentTagName: 'c2c-add-edit-design',
            componentFooterTagName: 'c2c-add-edit-design-footer',
            imageStyle: (regionId) => this.isOverlayOnlyC2C(regionId) ? getSpriteAsIconStyle('sprite-c2c-e2-e3') : getSpriteAsIconStyle('sprite-c2c-hna'),
            availableRegions: this.getAvailableDesignTypeRegions(),

            isEnabled: (regionId) => !this.isConcrete2ConcreteConnectionTypeDisabled(regionId),
            isAvailable: (regionId) => { return !this.isEntryPointSplittedForRegion(regionId); },
            getDesignName: () => designName ?? this.designService.getNewDesignName(false)
        };
    }

    public concreteOverlayDesignInfo(designName?: string) : IDesignInfo {
        return {
            order: getModuleOrder(Constants.ModuleIndex, 1),
            id: 'add-edit-design-image-container-concrete2concrete-overlay',
            designTypeId: DesignTypeId.Concrete2Concrete,
            connectionType: [ConnectionType.ConcreteOverlay],
            title: this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.ConcreteOverlay.DesignType'),
            titleTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.ConcreteOverlay.DesignType',
            mainTagName: 'c2c-main',
            componentTagName: 'c2c-add-edit-design',
            componentFooterTagName: 'c2c-add-edit-design-footer',
            imageStyle: () => getSpriteAsIconStyle('sprite-c2c-e2-e3'),
            availableRegions: this.getAvailableDesignTypeRegions(),

            isEnabled: (regionId) => !this.isConcreteOverlayConnectionTypeDisabled(regionId),
            isAvailable: (regionId) => getAvailableConnectionTypesForRegionC2C(this.codeList, regionId).includes(ConnectionType.ConcreteOverlay)
                && this.isEntryPointSplittedForRegion(regionId) && environment.c2cEnabled,
            getDesignName: () => designName ?? this.designService.getNewDesignName(true)
        };
    }

    public postInstallRebarDesignInfo(designName?: string) : IDesignInfo {
        return {
            order: getModuleOrder(Constants.ModuleIndex, 2),
            id: 'add-edit-design-image-container-concrete2concrete-pir',
            designTypeId: DesignTypeId.Concrete2Concrete,
            connectionType: [ConnectionType.Splices, ConnectionType.StructuralJoints],
            title: this.translate('Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.PostInstallRebar.DesignType'),
            titleTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.PostInstallRebar.DesignType',
            mainTagName: 'c2c-main',
            componentTagName: 'c2c-add-edit-design',
            imageStyle: () => getSpriteAsIconStyle('sprite-c2c-hna'),
            availableRegions: this.getAvailableDesignTypeRegions(),

            isEnabled: (regionId) => !this.isPirConnectionTypeDisabled(regionId),
            isAvailable: (regionId) => this.isPirConnectionTypeVisible(regionId) && !this.c2cOverlayDemoOnly,
            getDesignName: () => designName ?? this.designService.getNewDesignName(true)
        };
    }

    public getUserSettingsInfo(): IUserSettingsInfo {
        const settings = new UserSettingsC2C(false);
        return {
            userSettingsKey: 'c2c',
            userSettings: {} as unknown as UserSettingsSection,
            userSettingsRoot: {
                rebarFavorites: settings.rebarFavorites,
                overlayvirtualtourSeen: settings.overlayvirtualtourSeen
            } as unknown as UserSettingsSection,
            applicationSettings: { concrete2Concrete: settings.application.concrete2Concrete } as unknown as UserSettingsSection,
            quickStart: settings.quickStart as unknown as UserSettingsSection,
            applicationImportLoads: settings.applicationImportLoads as unknown as UserSettingsSection,
            applicationModelDisplayOptions: settings.applicationModelDisplayOptions as unknown as UserSettingsSection
        };
    }

    public getAppSettings(): AppSettings[] {
        if (!environment.c2cEnabled && !environment.c2cOverlayDemoEnabled) {
            return [];
        }

        return [
            {
                order: getModuleOrder(Constants.ModuleIndex, 0),
                name: 'app-settings-c2c-module',
                collapsed: false,
                titleKey: 'Agito.Hilti.Profis3.ApplicationSettings.Menu.Rebar',
                title: this.translate('Agito.Hilti.Profis3.ApplicationSettings.Menu.Rebar'),
                tooltipKey: 'Agito.Hilti.Profis3.Main.Region.ShowHide',
                sections: [
                    {
                        id: 'application-settings-concrete-to-concrete-fixing',
                        titleKey: 'Agito.Hilti.Profis3.ApplicationSettings.Menu.RebarConcreteToConcreteConfiguration',
                        title: this.translate('Agito.Hilti.Profis3.ApplicationSettings.Menu.RebarConcreteToConcreteConfiguration'),
                        type: ApplicationSettingsDisplayType.quickStartConcreteToConcrete,
                        componentTagName: 'c2c-app-settings-c2c-fixing',

                        loadSettings: () => this.appSettings.loadSettings(),
                        handleRegionChange: (data: unknown, regionId: number) => this.appSettings.handleRegionChange(data as IC2CFixing, regionId),
                        updateSettings: (data: unknown) => this.appSettings.updateSettings(data as IC2CFixing)
                    }
                ]
            }
        ];
    }

    public getQuickStartApplications(): IQuickStartApplication[] {
        this.designTypes = normalizeArray(this.codeList.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignType[], 'id');
        return [
            this.getQuickStartApplicationConcrete2ConcreteNotSplitted(),
            this.getQuickStartApplicationConcrete2ConcreteSplittedOverlay(),
            this.getQuickStartApplicationConcrete2ConcreteSplittedPir(),
            this.getQuickStartApplicationConcrete2ConcreteE2E3(),
            this.getQuickStartApplicationConcrete2ConcreteCollapsedOverlay()
        ];
    }

    public getDesignListInfo(): IDesignListInfo[] {
        return [
            {
                // Concrete
                order: getModuleOrder(Constants.ModuleIndex, 0),
                designTypeId: DesignTypeId.Concrete2Concrete,
                designTypeName: this.translate('Agito.Hilti.Profis3.CodeList.DesignTypeEntity.Concrete2Concrete'),
                designTypeImage: getSpriteAsIconStyle('sprite-model3d-c2c'),

                isEnabled: () => true,
                checkDesign: (design: IBaseDesign) => this.ngZone.run(() =>
                    this.calculationService.openFromDocumentDesignC2C(design)
                ),
                openFromDocumentDesign: (documentDesign: IDesignListItem) => this.ngZone.run(() =>
                    this.designService.openFromDocumentDesign(documentDesign)
                ),
                openDesignSettings: (designId: string, _designName: string, _regionId: number, onDesignSettingsClosed?: () => void) => this.ngZone.run(() =>
                    this.openDesignSettings(designId, onDesignSettingsClosed)
                ),
                openTemplate: (templateId: string) => this.designService.openTemplate(templateId),
                openTemplateSettings: (templateId: string, onDesignSettingsClosed?: () => void) => this.ngZone.run(() =>
                    this.openTemplateSettings(templateId, onDesignSettingsClosed)
                ),
                newDesignFromTemplate: (designId: string, projectId?: string, designName?: string) => this.ngZone.run(() =>
                    this.designService.newDesignFromTemplate(designId, this.isEntryPointSplitted, projectId, designName)
                ),
                getDesign: async (designId: string) => this.ngZone.run(async () => {
                    const documentDesign = this.documentService.findDesignById(designId);
                    return (await this.calculationService.openFromDocumentDesignC2C(documentDesign)).design;
                }),
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, 'sprite-model3d-c2c', getDesignThumbnail),
                toDisplayDesignTemplate: (template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string) => this.designService.toDisplayDesignTemplate(template, 'sprite-model3d-c2c', getDesignThumbnail),
                getProjectDesignFromDesign: (design: Design) => (design as DesignC2C).designData.projectDesignC2C
            }
        ];
    }

    public getDesignInfo(designName?: string): IDesignInfo[] {
        if (!environment.c2cEnabled && !environment.c2cOverlayDemoEnabled) {
            return [];
        }

        return [
            // C2C (not splitted)
            this.concrete2ConcreteDesignInfo(designName),
            // C2C (entry point splitted; part Overlay)
            this.concreteOverlayDesignInfo(designName),
            // C2C (entry point splitted; part PostInstalledRebar / Splices)
            this.postInstallRebarDesignInfo(designName)
        ];
    }

    public showNewLabelForConcrete2Concrete(connectionType?: ConnectionType): boolean {

        const methodGroupConnTypeStandardRegions = this.codeList.projectCodeListsC2C != null
            ? this.codeList.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[]
            : [];

        const quickStartConcrete2ConcreteSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;
        const regionId = quickStartConcrete2ConcreteSettings.general_region?.id != null && quickStartConcrete2ConcreteSettings.general_region.id > 0
            ? quickStartConcrete2ConcreteSettings.general_region.id
            : this.userSettingsService.settings.application.general.regionId.value;

        if (connectionType != null) {
            let designStandardId = quickStartConcrete2ConcreteSettings.calculation_designStandardC2C.id;

            if (connectionType == ConnectionType.ConcreteOverlay) {
                designStandardId = quickStartConcrete2ConcreteSettings.calculation_overlayDesignStandardC2C.id;
            }

            return methodGroupConnTypeStandardRegions.some(x =>
                x.connectionTypeId == connectionType &&
                x.regionId == regionId &&
                x.designStandardId == designStandardId &&
                x.newStatus
            );
        } else {
            return methodGroupConnTypeStandardRegions.some(x =>
                x.connectionTypeId != ConnectionType.ConcreteOverlay &&
                x.regionId == regionId &&
                x.designStandardId == quickStartConcrete2ConcreteSettings.calculation_designStandardC2C.id &&
                x.newStatus) ||
                methodGroupConnTypeStandardRegions.some(x =>
                    x.connectionTypeId == ConnectionType.ConcreteOverlay &&
                    x.regionId == regionId &&
                    x.designStandardId == quickStartConcrete2ConcreteSettings.calculation_overlayDesignStandardC2C.id &&
                    x.newStatus);
        }
    }

    public getFavoritesInfo(): IFavoritesInfo {
        return {
            getFavoritesType: (menuType: MenuType, designType: number, designStandard?: number, connectionType?: number) => this.favoritesService.getFavoritesTypeC2C(menuType, designType, designStandard ?? 0, connectionType ?? 0),
            getMenuRegionIdFromFavorites: (id: string, designType: number) => this.favoritesService.getMenuRegionIdFavorites(id, designType)
        };
    }

    public getTrackingInfo(): ITrackingInfo[] {
        return [
            {
                designTypeId: DesignTypeId.Concrete2Concrete,

                saveTrackingData: (
                    design: Design,
                    appVersion?: string
                ) => {
                    const designC2C = design as DesignC2C;

                    return this.trackingService.saveTrackingActData(
                        designC2C.designData.projectDesignC2C,
                        designC2C.usageCounterC2C.toUsageCounterC2C(),
                        designC2C.projectOpenType,
                        design.id,
                        designC2C.createdFromTemplate,
                        designC2C.templateId,
                        appVersion ?? ''
                    );
                },

                trackOnTemplateClose: (
                    design: Design,
                    appVersion: string,
                ) => {
                    const designC2C = design as DesignC2C;

                    return this.trackingService.trackOnTemplateClose(
                        designC2C.designData.projectDesignC2C,
                        designC2C.usageCounterC2C.toUsageCounterC2C(),
                        designC2C.projectOpenType,
                        designC2C.createdFromTemplate,
                        designC2C.templateId ?? '',
                        appVersion
                    );
                },

                trackOnDesignOpen: (
                    design: Design,
                    appVersion?: string
                ) => {
                    const designC2C = design as DesignC2C;

                    return this.trackingService.trackOnDesignOpen(
                        designC2C.id,
                        designC2C.connectionType,
                        appVersion ?? '',
                        designC2C.designType,
                        designC2C.designStandardC2C,
                    );
                },
                trackOnDesignClose: (
                    design: Design,
                    appVersion?: string,
                ) => {
                    const designC2C = design as DesignC2C;

                    return this.trackingService.trackOnDesignClose(
                        designC2C.designData.projectDesignC2C,
                        designC2C.usageCounterC2C.toUsageCounterC2C(),
                        designC2C.projectOpenType,
                        design.id,
                        designC2C.createdFromTemplate,
                        designC2C.templateId ?? '',
                        appVersion ?? ''
                    );
                },

                handleDesignBrowserUnload: (
                    sessionKey: string,
                    design: Design,
                    appVersion?: string
                ) => {
                    const designC2C = design as DesignC2C;

                    return this.trackingService.handleDesignBrowserUnload(
                        sessionKey,
                        designC2C.projectDesign,
                        designC2C.usageCounterC2C,
                        designC2C.projectOpenType,
                        design.id,
                        designC2C.createdFromTemplate,
                        designC2C.templateId,
                        appVersion
                    );
                }
            }
        ];
    }

    public getDesignManagementInfo(): IDesignManagement[] {
        return [
            {
                designTypeId: DesignTypeId.Concrete2Concrete,

                getMetaDataFromDesign: (design: object) => {
                    return getMetaDataFromDesign(design as DesignC2C);
                },

                openDesignExclusive: (design: IBaseDesign) => {
                    return this.documentService.openDesignExclusiveC2C(design);
                },

                setDesignProperties: (design: Design, documentDesign: IDesignListItem) => {
                    this.documentService.setDesignPropertiesC2C(design as DesignC2C, documentDesign);
                },

                updateDesignWithNewContent: (
                    designId: string,
                    projectId: string,
                    designName: string,
                    contentOverride: object,
                    metadataOverride: DesignExternalMetaDataCommon,
                    displayDesign?: IDetailedDisplayDesignCommon,
                    unlock?: boolean,
                    exclusiveLock?: boolean,
                    documentAccessMode?: DocumentAccessMode
                ) => {
                    return this.documentService.updateDesignWithNewContentC2C(
                        designId,
                        projectId,
                        designName,
                        contentOverride as ProjectDesignEntityC2C,
                        metadataOverride as DesignExternalMetaDataC2C,
                        displayDesign as IDetailedDisplayDesign,
                        unlock,
                        exclusiveLock,
                        documentAccessMode
                    );
                }
            }
        ];
    }


    private getQuickStartApplicationConcrete2ConcreteNotSplitted(): IQuickStartApplication {
        const designTypeKey = this.IsNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.Main.QuickStart.Concrete2Concrete.DesignType' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.DesignType';
        return {
            order: getModuleOrder(Constants.ModuleIndex, 0),
            showInQuickStart: true,
            showInCollapsedState: false,

            designType: DesignTypeId.Concrete2Concrete,

            idQuickStart: 'quick-start-button-c2c',
            imageStyle:  this.IsNewHomePageEnabled ? getSpriteAsIconStyle('sprite-concrete-to-concrete') : getSpriteAsIconStyle('sprite-c2c-module-hna'),
            actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.Action',
            designTypeTranslationKey: designTypeKey,
            designTypeTranslation: this.translate(designTypeKey),
            newLabelTranslationKey: 'Common.QuickStartButtons.NewStatus',

            isEnabled: () => this.allowConcrete2ConcreteQuickStart && !this.overlayOnlyC2C && !this.isEntryPointSplitted,
            isDesignTypeDisabled: () => false,
            getButtonTooltip: () => undefined,
            createRegionDesignStandardApprovalNumber: () => {
                const designSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;
                if (designSettings == null)
                    return '';

                return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_designStandardC2C);
            },
            showNewLabel: () => this.showNewLabelForConcrete2Concrete(),
            getNewDesignName: () => this.designService.getNewDesignName(false),
            createNewDesign: saveDesign => this.ngZone.run(() => this.createNewQuickStartDesign(false, undefined, saveDesign))
        };
    }

    private getQuickStartApplicationConcrete2ConcreteSplittedOverlay(): IQuickStartApplication {
        const designTypeKey = this.IsNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.Main.QuickStart.Concrete2Concrete.ConcreteOverlay.DesignType' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.ConcreteOverlay.DesignType';
        return {
            order: getModuleOrder(Constants.ModuleIndex, 1),
            showInQuickStart: true,
            showInCollapsedState: false,

            designType: DesignTypeId.Concrete2Concrete,

            idQuickStart: 'quick-start-button-c2c-overlay',
            imageStyle: this.IsNewHomePageEnabled ? getSpriteAsIconStyle('sprite-concrete-to-concrete-overlay-e2-e3') : getSpriteAsIconStyle('sprite-c2c-overlay-e2-e3'),
            actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.ConcreteOverlay.Action',
            designTypeTranslationKey: designTypeKey,
            designTypeTranslation: this.translate(designTypeKey),
            newLabelTranslationKey: 'Common.QuickStartButtons.NewStatus',

            isEnabled: () => this.allowConcrete2ConcreteQuickStart && !this.overlayOnlyC2C && this.isEntryPointSplitted && this.isOverlayEnabled,
            isDesignTypeDisabled: () => false,
            getButtonTooltip: () => undefined,
            createRegionDesignStandardApprovalNumber: () => {
                const designSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;
                if (designSettings == null)
                    return '';

                return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_overlayDesignStandardC2C);
            },
            showNewLabel: () => this.showNewLabelForConcrete2Concrete(ConnectionType.ConcreteOverlay),
            getNewDesignName: () => this.designService.getNewDesignName(false),
            createNewDesign: saveDesign => this.ngZone.run(() => this.createNewQuickStartDesign(false, ConnectionType.ConcreteOverlay, saveDesign))
        };
    }

    private getQuickStartApplicationConcrete2ConcreteSplittedPir(): IQuickStartApplication {
        const designTypeKey = this.IsNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.Main.QuickStart.Concrete2Concrete.PostInstallRebar.DesignType' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.PostInstallRebar.DesignType';
        return {
            order: getModuleOrder(Constants.ModuleIndex, 2),
            showInQuickStart: true,
            showInCollapsedState: false,

            designType: DesignTypeId.Concrete2Concrete,

            idQuickStart: 'quick-start-button-c2c-pir',
            imageStyle: this.IsNewHomePageEnabled ? getSpriteAsIconStyle('sprite-concrete-to-concrete') : getSpriteAsIconStyle('sprite-c2c-module-hna'),
            actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.PostInstallRebar.Action',
            designTypeTranslationKey: designTypeKey,
            designTypeTranslation: this.translate(designTypeKey),
            newLabelTranslationKey: 'Common.QuickStartButtons.NewStatus',

            isEnabled: () => this.allowConcrete2ConcretePirQuickStart && !this.overlayOnlyC2C && this.isEntryPointSplitted && !this.c2cOverlayDemoOnly,
            isDesignTypeDisabled: () => false,
            getButtonTooltip: () => undefined,
            createRegionDesignStandardApprovalNumber: () => {
                const designSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;
                if (designSettings == null)
                    return '';

                return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_designStandardC2C);
            },
            showNewLabel: () => this.showNewLabelForConcrete2Concrete(ConnectionType.Splices),
            getNewDesignName: () => this.designService.getNewDesignName(this.isEntryPointSplitted),
            createNewDesign: saveDesign => this.ngZone.run(() => this.createNewQuickStartDesign(this.isEntryPointSplitted, ConnectionType.Splices, saveDesign))
        };
    }

    private getQuickStartApplicationConcrete2ConcreteE2E3(): IQuickStartApplication {
        const designTypeKey = this.IsNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.Main.QuickStart.ConcreteOverlay.DesignType' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.ConcreteOverlay.DesignType';
        const collapsedTextTranslationKey = this.IsNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.Main.QuickStart.ConcreteOverlay.Collapsed' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.ConcreteOverlay.Collapsed';
        return {
            order: getModuleOrder(Constants.ModuleIndex, 3),
            showInQuickStart: true,
            showInCollapsedState: true,

            designType: DesignTypeId.Concrete2Concrete,

            idQuickStart: 'quick-start-button-c2c-overlay',
            idCollapsed: 'quick-start-button-collapsed-concreteoverlay',
            imageStyle: this.IsNewHomePageEnabled ? getSpriteAsIconStyle('sprite-concrete-to-concrete-overlay-e2-e3') : getSpriteAsIconStyle('sprite-c2c-overlay-e2-e3'),
            actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.ConcreteOverlay.Action',
            designTypeTranslationKey: designTypeKey,
            designTypeTranslation: this.translate(designTypeKey),
            collapsedTextTranslationKey: collapsedTextTranslationKey,
            collapsedTextTranslation: this.translate(collapsedTextTranslationKey),

            isEnabled: () => this.allowConcrete2ConcreteQuickStart && this.overlayOnlyC2C,
            isDesignTypeDisabled: () => false,
            getButtonTooltip: () => undefined,
            createRegionDesignStandardApprovalNumber: () => {
                const designSettings = this.userSettingsService.quickStartConcrete2ConcreteSettings;
                if (designSettings == null)
                    return '';

                return this.designService.createRegionDesignStandardApprovalNumber(designSettings.general_region, designSettings.calculation_designStandardC2C);
            },
            getNewDesignName: () => this.designService.getNewDesignName(false),
            createNewDesign: saveDesign => this.ngZone.run(() => this.createNewQuickStartDesign(false, undefined, saveDesign))
        };
    }

    private getQuickStartApplicationConcrete2ConcreteCollapsedOverlay(): IQuickStartApplication {
        const collapsedTextTranslationKey = this.IsNewHomePageEnabled ? 'Agito.Hilti.Profis3.HomePage.Main.QuickStart.Concrete2Concrete.Collapsed' : 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete2Concrete.Collapsed';
        return {
            order: getModuleOrder(Constants.ModuleIndex, 4),
            showInQuickStart: false,
            showInCollapsedState: true,

            designType: DesignTypeId.Concrete2Concrete,

            idCollapsed: 'quick-start-button-collapsed-concrete2concrete',
            collapsedTextTranslationKey: collapsedTextTranslationKey,
            collapsedTextTranslation: this.translate(collapsedTextTranslationKey),

            isEnabled: () => this.allowConcrete2ConcreteQuickStart && !this.overlayOnlyC2C,
            isDesignTypeDisabled: () => false,
            getButtonTooltip: () => undefined,
            createRegionDesignStandardApprovalNumber: () => '',
            getNewDesignName: () => this.designService.getNewDesignName(false),
            createNewDesign: saveDesign => this.ngZone.run(() => this.createNewQuickStartDesign(false, undefined, saveDesign))
        };
    }

    private async openTemplateSettings(templateId: string, onClosed?: () => void) {
        const template = await this.designTemplateService.getById(templateId);
        const projectDesign = convertStringToLatestDesign(template.ProjectDesign);
        const design = await this.calculationService.createAndOpenTemplateC2C(projectDesign, template.DesignTemplateDocumentId, template.DesignTemplateName);
        if (!design.confirmChangeInProgress) {
            this.onTemplateOpenSettings(projectDesign, template, design, onClosed);
        }
        else {
            design.on(DesignEvent.designChangesConfirmed, () => this.onTemplateOpenSettings(projectDesign, template, design, onClosed));
        }
    }

    private async onTemplateOpenSettings(projectDesign: ProjectDesignEntityC2C, template: DesignTemplateEntity, design: DesignC2C, onClosed?: () => void): Promise<void> {
        this.designStandardId = design.designStandardC2C?.id;
        const designInfo = this.getDesignInfo().find(x => x.designTypeId == design.designTypeId && (x.connectionType?.includes(design.connectionType)) && x.isAvailable(design.regionId));
        if (designInfo == undefined) {
            throw new Error('Design info not found');
        }

        this.modalService.openAddEditDesignFromModule({
            design: {
                id: template.DesignTemplateDocumentId,
                name: template.DesignTemplateName,
                projectId: null,
                projectName: null,
                region: this.getRegionById(projectDesign.options.regionId),
                designType: DesignTypeId.Concrete2Concrete,
                displayDesignType: DisplayDesignType.template,
                anchorName: template.AnchorName,
                approvalNumber: template.ApprovalNumber,
                designTemplateDocumentId: template.DesignTemplateDocumentId,
                projectDesign: projectDesign,
                design: design
            },
            addEditType: AddEditType.edit,
            selectedModuleDesignInfo: designInfo,
            onDesignEdited: (design) => {
                const displayDesign = design as IDetailedDisplayDesign;
                const templateProjectDesign = detailedDisplayDesignToProjectDesignC2C(projectDesign, displayDesign);

                return this.designTemplateService.update({
                    designTemplateDocumentId: template.DesignTemplateDocumentId,
                    designTypeId: DesignTypeId.Concrete2Concrete,
                    designStandardId: templateProjectDesign.options.designStandard,
                    regionId: templateProjectDesign.options.regionId,
                    templateName: displayDesign.name,
                    anchorName: template.AnchorName,
                    approvalNumber: template.ApprovalNumber,
                    projectDesign: JSON.stringify(templateProjectDesign)
                });
            }
        }).closed
            .finally(() => {
                // template has no publish so we don't need to call result.design.publish();
                if (onClosed) {
                    onClosed();
                }
            });
    }

    private openDesignSettings(designId: string, onClosed?: () => void) {
        const documentDesign = this.documentService.findDesignById(designId);
        const promiseCatch = (response: any) => {
            if (response instanceof CantOpenDesignBecauseLockedByOtherUser) {
                this.modalService.openDocumentInUseWarning(response.username ?? '');
            }
            throw response;
        };

        return this.documentService.openDesignC2C(documentDesign)
            .then((projectDesign) => {
                this.calculationService.openFromProjectDesignC2C(projectDesign, documentDesign.id)
                    .then((result) => {
                        if (!result.design.confirmChangeInProgress) {

                            this.callPromise(documentDesign, result.design.designData.projectDesignC2C, result.design, onClosed);
                        }
                        else {
                            result.design.on(DesignEvent.designChangesConfirmed, () => this.callPromise(documentDesign, result.design.designData.projectDesignC2C, result.design, onClosed));
                        }

                    }).catch((response) => {
                        promiseCatch(response);
                    });
            })
            .catch((response) => {
                promiseCatch(response);
            });
    }

    private callPromise(documentDesign: IDesignListItem, projectDesign: ProjectDesignEntityC2C, design: DesignC2C, onClosed?: () => void) {
        this.designStandardId = design.designStandardC2C?.id;
        const designInfo = this.getDesignInfo().find(x => x.designTypeId == documentDesign.metaData.designType &&
            (x.connectionType?.includes(projectDesign.options.connectionType)) &&
            x.isAvailable(documentDesign.metaData.region));
        if (designInfo == undefined) {
            throw new Error('Design info not found');
        }

        this.modalService.openAddEditDesignFromModule({
            design: {
                id: documentDesign.id,
                name: documentDesign.designName,
                projectId: documentDesign.projectId,
                projectName: documentDesign.projectName,
                region: this.getRegionById(projectDesign.options.regionId),
                designType: DesignTypeId.Concrete2Concrete,
                displayDesignType: design.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
                anchorName: documentDesign.metaData.productName,
                approvalNumber: documentDesign.metaData.approvalNumber,
                designTemplateDocumentId: documentDesign.id,
                projectDesign: projectDesign,
                design: design
            },
            addEditType: AddEditType.edit,
            selectedModuleDesignInfo: designInfo,
            onDesignEdited: (design) => {
                const displayDesign = design as IDetailedDisplayDesign;
                if (displayDesign.displayDesignType != DisplayDesignType.template) {
                    return this.documentService.updateDesignWithNewContentC2C(
                        displayDesign.id,
                        displayDesign.projectId,
                        displayDesign.name,
                        projectDesign,
                        documentDesign.metaData as DesignExternalMetaDataC2C,
                        displayDesign,
                        true,
                        true,
                        DocumentAccessMode.Update
                    );
                }

                return this.designTemplateService.update({
                    designTemplateDocumentId: documentDesign.id,
                    designTypeId: DesignTypeId.Concrete2Concrete,
                    designStandardId: projectDesign.options.designStandard,
                    regionId: projectDesign.options.regionId,
                    templateName: displayDesign.name,
                    anchorName: documentDesign.metaData.productName,
                    approvalNumber: documentDesign.metaData.approvalNumber,
                    projectDesign: JSON.stringify(projectDesign)
                });
            }
        }).closed
            .finally(() => {
                if (onClosed) {
                    onClosed();
                }
            });
    }

    private getRegionById(regionId: number | null) {
        const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private translate(key: string) {
        return this.localization.getString(key);
    }

    private getAvailableDesignTypeRegions() {
        if (this.designTypes == null) {
            return [];
        }

        const designTypeItem = this.designTypes[DesignTypeId.Concrete2Concrete];
        if (designTypeItem?.allowedDesignStandardRegions == null) {
            return [];
        }

        return designTypeItem.allowedDesignStandardRegions.map(r => r.RegionId);
    }

    private isOverlayOnlyC2C(regionId: number) {
        return isOnlyOverlay(this.codeList, regionId);
    }

    private isConcreteOverlayConnectionTypeDisabled(regionId: number) {
        return this.isDisabled(regionId) ||
            !getAvailableConnectionTypesForRegionC2C(this.codeList, regionId).includes(ConnectionType.ConcreteOverlay) ||
            !this.isEntryPointSplittedForRegion(regionId) ||
            (environment.c2cForceAvailableCountriesEnabled && !environment.c2cForceAvailableCountries.includes(regionId));
    }

    private isPirConnectionTypeDisabled(regionId: number) {
        return !this.isEntryPointSplittedForRegion(regionId) ||
            this.isOverlayOnlyC2C(regionId) ||
            (environment.c2cForceAvailableCountriesEnabled && !environment.c2cForceAvailableCountriesPir.includes(regionId));
    }

    private isConcrete2ConcreteConnectionTypeDisabled(regionId: number) {
        return this.isDisabled(regionId) || this.isEntryPointSplittedForRegion(regionId);
    }

    private isDisabled(regionId: number) {
        const desingTypeCodeList = this.codeList.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignType[];

        const designTypeItem = desingTypeCodeList.find(item => item.id == DesignTypeId.Concrete2Concrete);
        const isDesignTypeAllowed = designTypeItem != null ? designTypeItem.allowedDesignStandardRegions?.some((row) => row.RegionId == regionId) : false;
        const allowedStandards = designTypeItem != null ? designTypeItem.allowedDesignStandardRegions?.find(row => row.RegionId == regionId) : null;
        return !isDesignTypeAllowed
            ||
            (
                allowedStandards != null
                && allowedStandards.DesignStandards.length == 1
            );
    }

    private isEntryPointSplittedForRegion(regionId: number) {
        const designStandards = this.appSettingsHelper.getDesignStandards(regionId);

        if (designStandards.filter(x => x.id == DesignStandardEnum.ACI).length == 0) {
            this._designStandardId = undefined;
        }

        return this.appSettingsHelper.isEntryPointSplitted(regionId, this._designStandardId) ?? false;
    }

    private isPirConnectionTypeVisible(regionId: number): boolean {
        return this.isEntryPointSplittedForRegion(regionId) && !this.isOverlayOnlyC2C(regionId);
    }

    private async createNewQuickStartDesign(isEntryPointSplittedPIR: boolean, connectionType: ConnectionType | undefined, saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        const quickStartSettingsC2C = this.userSettingsService.quickStartConcrete2ConcreteSettings;

        const designStandardId = this.getDesignStandardQuickSettings(quickStartSettingsC2C, isEntryPointSplittedPIR);
        const regionId = quickStartSettingsC2C.general_region != null && quickStartSettingsC2C.general_region.id > 0
            ? quickStartSettingsC2C.general_region.id
            : this.userSettingsService.settings.application.general.regionId.value as number;

        if (!this.applyToAllDesigns(this.userSettingsService, this.codeList, designStandardId) && !isEntryPointSplittedPIR) {
            const designInfo = this.getDesignInfo().find(x =>
                (connectionType == null || x.connectionType == null || x.connectionType?.includes(connectionType))
                && x.isAvailable(regionId)
            );
            if (designInfo != null) {
                this.modalService.openAddEditDesignFromModule({
                    selectedModuleDesignInfo: designInfo,
                    addEditType: AddEditType.addFromQuickStart,
                    design: {
                        designType: DesignTypeId.Concrete2Concrete,
                        region: this.getRegionById(regionId),
                        projectId: saveDesign.projectId,
                        projectName: saveDesign.projectName
                    }
                });
            }

            // Design will be created later, with add-edit-design popup.
            return Promise.reject({});
        }

        const currentConnectionType = !isEntryPointSplittedPIR
            ? this.userSettingsService.settings.application.concrete2Concrete.selectedConnectionTypeId.value
            : ConnectionType.Splices;
        const applicationType = !isEntryPointSplittedPIR
            ? this.userSettingsService.settings.application.concrete2Concrete.selectedApplicationTypeId.value
            : ApplicationType.SlabToSlab;

        const designMethodId = this.getDesignMethodQuickSettings(quickStartSettingsC2C, isEntryPointSplittedPIR);
		const isPirEu = designStandardId == DesignStandardEnum.ETAG && (connectionType == ConnectionType.Splices || connectionType == ConnectionType.StructuralJoints);

        const dataEntity: NewDesignDataEntityC2C = {
            regionId,
            designStandard: designStandardId,
            designMethod: designMethodId,
            projectName: saveDesign?.projectName,
            projectId: saveDesign?.projectId,
            designName: saveDesign?.designName,
			unitLength: quickStartSettingsC2C.units_length?.id as number,
			unitArea: quickStartSettingsC2C.units_area?.id as number,
			unitStress: quickStartSettingsC2C.units_stress?.id as number,
			unitForce: quickStartSettingsC2C.units_force?.id as number,
			unitMoment: quickStartSettingsC2C.units_moment?.id as number,
			unitTemperature: quickStartSettingsC2C.units_temperature?.id as number,
			unitForcePerLength: quickStartSettingsC2C.units_force_per_length?.id as number,
			unitMomentPerLength: quickStartSettingsC2C.units_moment_per_length?.id as number,
			unitDensity: quickStartSettingsC2C.units_density?.id as number,
			unitAreaPerLength: quickStartSettingsC2C.units_area_per_length?.id as number,
			language: undefined as unknown as string,
			numberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettingsService),
			numberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettingsService),
			applicationType: applicationType as number,
			connectionType: currentConnectionType as number,
			reportFirstPage: 1,
			reportLanguageId: this.userSettingsService.settings.application.general.languageId.value,
			reportPaperSize: ReportPaperSize.Unknown,
			reportType: ReportType.Unknown,
			maximumAreaReinforcement: MaximumAreaReinforcement.Unknown,
			minimumAreaReinforcement: MinimumAreaReinforcement.Unknown,
			optimizationStrategy: OptimizationStrategy.Unknown,
			shearReinforcementConcreteMember: false,
			spacingMaximum: SpacingMaximum.Unknown,
			strutTies: StrutTies.Unknown,
			enableDebugMode: this.sharedEnvironmentService.data?.debugModeEnabled ?? false,
			useGermanNationalAnnex: DesignMethodGroupHelper.isGermanNationalAnnexAvailable(isPirEu, regionId), // German national annex is selected as default for PIR EU designs in German region
            useDenmarkNationalAnnex: DesignMethodGroupHelper.isDenmarkNationalAnnexAvailable(this.featuresVisibilityService, regionId) && Boolean(
                defaultUiPropertyValue(UIProperty.Loads_C2C_UseDenmarkNationalAnnex, this.codeList, regionId, designStandardId, connectionType, applicationType ?? undefined, designMethodId)),
			metricEquation: MetricEquation.None,
		};

        if (isEntryPointSplittedPIR) {
            dataEntity.maximumAreaReinforcement = defaultUiPropertyValue(UIProperty.Option_C2C_MaximumAreaReinforcement, this.codeList, regionId) as number;
            dataEntity.minimumAreaReinforcement = defaultUiPropertyValue(UIProperty.Option_C2C_MinimumAreaReinforcement, this.codeList, regionId) as number;
            dataEntity.optimizationStrategy = defaultUiPropertyValue(UIProperty.Option_C2C_OptimizationStrategy, this.codeList, regionId) as number;
            dataEntity.spacingMaximum = defaultUiPropertyValue(UIProperty.Option_C2C_SpacingMaximum, this.codeList, regionId) as number;
            dataEntity.strutTies = defaultUiPropertyValue(UIProperty.Option_C2C_StrutTies, this.codeList, regionId) as number;
        }

        // create design
        const design = await this.calculationService.createAndOpenC2C(dataEntity);

        return {
            designId: design.id,
            path: UrlPath.main,
            design,
            success: true
        };
    }

    private getDesignStandardQuickSettings(quickStartSettings: QuickStartSettings, isEntryPointSplittedPIR: boolean): number {
        const connectionTypeId = this.userSettingsService.settings.application.concrete2Concrete.selectedConnectionTypeId.value;
        let designStandardId = quickStartSettings.calculation_designStandardC2C.id;
        if (!isEntryPointSplittedPIR && connectionTypeId == ConnectionType.ConcreteOverlay) {
            designStandardId = quickStartSettings.calculation_overlayDesignStandardC2C.id ?? quickStartSettings.calculation_designStandardC2C.id;
        }
        return designStandardId ?? null;
    }

    private getDesignMethodQuickSettings(quickStartSettings: QuickStartSettings, isEntryPointSplittedPIR: boolean): number {
        const connectionTypeId = this.userSettingsService.settings.application.concrete2Concrete.selectedConnectionTypeId.value;
        let designMethodId = quickStartSettings.calculation_designMethodC2C.id;
        if (!isEntryPointSplittedPIR && connectionTypeId == ConnectionType.ConcreteOverlay) {
            designMethodId = quickStartSettings.calculation_overlayDesignMethodC2C.id ?? quickStartSettings.calculation_designMethodC2C.id;
        }
        return designMethodId ?? null;
    }

    private applyToAllDesigns(userSettings: UserSettingsService, codeList: CodeListService, designStandardId: number): boolean {
        if (userSettings.settings.quickStart.concrete2Concrete.applicationState.value != ApplicationState.SelectedConnection ||
            userSettings.settings.application.concrete2Concrete.selectedConnectionTypeId.value == null ||
            designStandardId == null) {
            return false;
        }

        const selectedConnectionType = (codeList.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionTypeC2C[])
            .find((item) => item.id == userSettings.settings.application.concrete2Concrete.selectedConnectionTypeId.value);

        const applicationTypes = codeList.projectCodeListsC2C[ProjectCodeList.ApplicationTypesC2C] as ApplicationTypeC2C[];
        const availableConnections = (selectedConnectionType?.applicationTypes ?? [])
            .map(id => {
                return applicationTypes.find(x => x.id == id);
            })
            .filter(appType => (appType?.designStandards ?? []).some(id => id == designStandardId)) ?? [];

        if (availableConnections.length > 0 && userSettings.settings.application.concrete2Concrete.selectedApplicationTypeId.value != null) {
            return availableConnections.find((appType) => appType?.id == userSettings.settings.application.concrete2Concrete.selectedApplicationTypeId.value) != null;
        }

        if (availableConnections.length == 0 && userSettings.settings.application.concrete2Concrete.selectedApplicationTypeId.value == null) {
            return selectedConnectionType != null;
        }

        return false;
    }
}
