import {
    Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { RadioButtonProps, RadioLook } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { DesignCodeList as DesignCodeListC2C } from '../../../shared/entities/design-code-list';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { IGridImageSelectItem, IGridImageSelectProps } from '../grid-image-select/grid-image-select.component';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { ApplicationType as ApplicationTypeC2C } from '../../../shared/entities/code-lists/application-type';
import { ConnectionType as ConnectionTypeC2C } from '../../../shared/entities/code-lists/connection-type';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { ApplicationType, ConnectionType } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { FireDuration } from '../../../shared/entities/code-lists/fire-duration';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UserSettingsService } from '../../services/user-settings.service';
import { getNumberDecimalSeparator, getNumberGroupSeparator } from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { UnitService } from '../../services/unit.service';

interface IDesignConsiderationData {
    selectedConnectionType: ConnectionType;
    selectedApplicationType: ApplicationType;
    selectedfireApplicationType: ApplicationType;
    isExtensionAtSupport: boolean;
    isHnaFireExtensionAtSupportCheckbox: boolean;
    isXDirectionContinuous: boolean;
    seismicLoadMultiplier: number;
}

const enum FireResistanceRequirement {
    No = 0,
    Yes = 1
}

const enum SeismicConditions {
    No = 0,
    Yes = 1
}

@Component({
    templateUrl: './design-consideration.component.html',
    styleUrls: ['./design-consideration.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignConsiderationComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    @Input()
    public data!: IDesignConsiderationData;

    @Output()
    public selectedConnectionTypeChange = new EventEmitter<ConnectionType>();

    public requiredValidator = Validators.required;
    public submitted = false;
    public isExtensionAtSupportVisible!: boolean;
    public isXDirectionContinuousVisible!: boolean;
    public isFireResistanceDropdownDisabled!: boolean;
    public showRequiredLabel!: boolean;
    public showSingleRebarMessage!: boolean;
    public selectConnectionTypeC2C: IGridImageSelectProps = {};
    public selectApplicationTypeC2C: IGridImageSelectProps = {};
    public seismicLoadMultiplierProp!: NumericTextBoxProps;

    // checkboxes
    public isExtensionAtSupportCheckbox!: CheckboxButtonProps<boolean>;
    public isHnaFireExtensionAtSupportCheckbox!: CheckboxButtonProps<boolean>;
    public isXDirectionContinuousCheckbox!: CheckboxButtonProps<boolean>;

    // radio buttons
    public fireResistanceRequirementRadio!: RadioButtonProps<FireResistanceRequirement>;
    public seismicConditionsRadio!: RadioButtonProps<SeismicConditions>;

    // dropdowns
    public fireApplicationTypeDropdown!: DropdownProps<number>;
    public fireResistanceDropdown!: DropdownProps<number>;

    constructor(
        public localizationService: LocalizationService,
        private codeListC2C: CodeListServiceC2C,
        private user: UserService,
        private userSettings: UserSettingsService,
        private unitService: UnitService,
        private modal: ModalService,
        private calculationService: CalculationServiceC2C,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.data = {
            selectedConnectionType: this.design.connectionType,
            selectedApplicationType: this.design.applicationType,
            selectedfireApplicationType: this.design.projectDesign.loads.fireApplicationType,
            isExtensionAtSupport: this.design.isExtensionAtSupport ?? false,
            isHnaFireExtensionAtSupportCheckbox: this.design.isFireExtensionAtSupport ?? false,
            isXDirectionContinuous: this.design.isXDirectionContinuous ?? true,
            seismicLoadMultiplier: this.design.projectDesign.loads.seismicLoadMultiplier
        };
    }

    public get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        this.initControls();

        if (this.design.isPirEu) {
            const connectionTypes = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionTypeC2C[];
            const connectionTypesItems = this.getConnectionTypesItems(connectionTypes);

            this.selectConnectionTypeC2C = {
                numberOfColumns: connectionTypesItems.filter(x => !x.hidden).length,
                items: connectionTypesItems.filter(x => !x.hidden),
                selectedValue: this.data.selectedConnectionType
            };
            this.selectApplicationTypeC2C = {
                selectedValue: this.data.selectedApplicationType,
            };

            this.onSelectConnectionTypeC2C(this.data.selectedConnectionType);
        } else if (this.design.isPirHna) {
            this.initSeismicLoadMultiplierProp();
        }

        includeSprites(this.elementRef.nativeElement.shadowRoot, 'sprite-info-tooltip');
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public infoExtensionAtSupportPopup() {
        this.modal.openInfoDialogC2C('OpenExtensionAtSupportPopup');
    }

    public infoContinuousXDirectionPopup() {
        this.modal.openInfoDialogC2C('OpenContinuousInXPopup');
    }

    private getPirEuMappedApplicationTypes() {
        const connectionTypes = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionTypeC2C[];
        const applicationTypes = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ApplicationTypesC2C] as ApplicationTypeC2C[];

        const connectionApplicationsIds = connectionTypes.find(conn => conn.id == this.data.selectedConnectionType)?.applicationTypes ?? [];
        const connectionApplications = applicationTypes.filter(app => connectionApplicationsIds.includes(app.id ?? 0));

        const designStandardApplicationTypes = connectionApplications.filter(appType => appType.designStandards?.some(id => id == this.design.designStandardId));

        return designStandardApplicationTypes
            .map((appType) => ({
                image: appType.image,
                name: this.translate('Agito.Hilti.C2C.CodeList.ApplicationTypeEntity.' + appType.displayKey),
                id: appType.id,
                hidden: appType.hidden,
                disabled: appType.hidden,
                displayKey: appType.displayKey
            })
        );
    }

    private updateApplicationType(connectionTypeId: number) {
        const applicationTypesItems = this.getPirEuMappedApplicationTypes();

        this.selectApplicationTypeC2C.numberOfColumns = applicationTypesItems.filter(x => !x.hidden).length;
        this.selectApplicationTypeC2C.items = applicationTypesItems.filter(x => !x.hidden);
        this.selectApplicationTypeC2C.selectedValue = applicationTypesItems.some((x) => x.id == this.selectApplicationTypeC2C.selectedValue && !x.disabled) ?
            this.selectApplicationTypeC2C.selectedValue : this.getDefaultValueForApplicationTypeC2C(connectionTypeId, applicationTypesItems);
    }

    public hideFireAndSeismicSection(): boolean {
        if (this.data.selectedApplicationType == ApplicationType.Single) {
            this.showSingleRebarMessage = true;
            this.updateFireResistanceSettings(true, FireResistanceRequirement.No);
            this.seismicConditionsRadio.selectedValue = SeismicConditions.No;
            this.seismicConditionsRadio.disabled = true;
            return true;
        } else {
            this.showSingleRebarMessage = false;
            this.seismicConditionsRadio.disabled = false;
            return false;
        }
    }

    public showOrHideFireSection() {
        if (this.data.selectedApplicationType == ApplicationType.ColumnToWall || this.data.selectedApplicationType == ApplicationType.BeamToSlab) {
            this.updateFireResistanceSettings(true, FireResistanceRequirement.No);
        } else {
            this.fireResistanceRequirementRadio.disabled = false;
            this.isFireResistanceDropdownDisabled = this.fireResistanceRequirementRadio.selectedValue === FireResistanceRequirement.No;
        }
    }

    private updateFireResistanceSettings(isDisabled: boolean, selectedValue: FireResistanceRequirement) {
        this.fireResistanceRequirementRadio.selectedValue = selectedValue;
        this.fireResistanceRequirementRadio.disabled = isDisabled;
        this.isFireResistanceDropdownDisabled = isDisabled;
        this.showRequiredLabel = !isDisabled;
    }

    public onSelectConnectionTypeC2C(itemId: number) {
        this.setSelectedConnectionType(itemId);
        this.updateApplicationType(itemId);
        this.onSelectApplicationTypeC2C(this.selectApplicationTypeC2C.selectedValue ?? 0);
    }

    public onSelectApplicationTypeC2C(itemId: number) {
        this.data.selectedApplicationType = itemId;
        this.isExtensionAtSupportVisible = this.extensionAtSupportVisibility();
        this.isXDirectionContinuousVisible = this.continuousXDirectionVisibility();
        this.showOrHideFireSection();
    }

    public onChangeFireApplicationTypeDropdown(itemId: number) {
        this.data.selectedfireApplicationType = itemId;
        this.fireApplicationTypeDropdown.selectedValue = itemId;
    }

    public onChangeFireResistanceRequirement(itemId: FireResistanceRequirement) {
        this.fireResistanceRequirementRadio.selectedValue = itemId;
        this.isFireResistanceDropdownDisabled = itemId === FireResistanceRequirement.No;

        if (this.isFireResistanceDropdownDisabled){
            this.initFireResistanceDropdown();
            this.showRequiredLabel = false;
        }
    }

    public onChangeFireResistanceDropdown(itemId: number) {
        this.fireResistanceDropdown.selectedValue = itemId;
    }

    public onChangeExtensionAtSupportCheckbox(isChecked: Set<boolean>) {
        this.isExtensionAtSupportCheckbox.selectedValues = isChecked;
        this.data.isExtensionAtSupport = isChecked.has(true);

        if (this.design.isPirHna) {
            this.updateHnaFireApplicationTypeDropdown();
        }
    }

    public showUserDefinedDisclaimer() {
        return this.design.isPirEu && (this.seismicConditionsRadio.selectedValue || this.fireResistanceRequirementRadio.selectedValue);
    }

    private initControls() {
        this.isExtensionAtSupportCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'design-consideration-c2c-extension-at-support-checkbox',
            itemText: this.translate('Agito.Hilti.C2C.ConnectionApplication.ExtensionAtSupport'),
            checked: this.data.isExtensionAtSupport
        });

        if (this.design.isPirEu) {
            this.isXDirectionContinuousCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
                itemId: 'design-consideration-c2c-continuous-in-x-direction-checkbox',
                itemText: this.translate('Agito.Hilti.C2C.ConnectionApplication.XDirectionContinous'),
                checked: this.data.isXDirectionContinuous
            });
        } else if (this.design.isPirHna) {
            this.initHnaFireApplicationTypeDropdown();
        }

        this.fireResistanceRequirementRadio = {
            title: this.translate('Agito.Hilti.C2C.DesignConsideration.FireResistanceRequirement.Title'),
            look: RadioLook.Horizontal,
            items: [
                {
                    text: this.translate('Agito.Hilti.C2C.DesignConsideration.FireResistanceRequirement.Yes'),
                    value: FireResistanceRequirement.Yes,
                    disabled: false,
                    id: 'design-consideration-c2c-fire-resistance-requirement-1-radio'
                },
                {
                    text: this.translate('Agito.Hilti.C2C.DesignConsideration.FireResistanceRequirement.No'),
                    value: FireResistanceRequirement.No,
                    disabled: false,
                    id: 'design-consideration-c2c-fire-resistance-requirement-2-radio'
                }
            ],
            selectedValue: FireResistanceRequirement.Yes
        };

        this.seismicConditionsRadio = {
            title: this.translate('Agito.Hilti.C2C.DesignConsideration.SeismicConditions.Title'),
            look: RadioLook.Horizontal,
            items: [
                {
                    text: this.translate('Agito.Hilti.C2C.DesignConsideration.SeismicConditions.Yes'),
                    value: SeismicConditions.Yes,
                    disabled: false,
                    id: 'design-consideration-c2c-seismic-conditions-1-radio'
                },
                {
                    text: this.translate('Agito.Hilti.C2C.DesignConsideration.SeismicConditions.No'),
                    value: SeismicConditions.No,
                    disabled: false,
                    id: 'design-consideration-c2c-seismic-conditions-2-radio'
                }
            ],
            selectedValue: SeismicConditions.Yes
        };

        // This should be done after fire resistance radio button is initialized
        this.isFireResistanceDropdownDisabled = this.fireResistanceRequirementRadio.selectedValue === FireResistanceRequirement.No;

        this.initFireResistanceDropdown();
    }

    private initSeismicLoadMultiplierProp() {
        const defaultDevelopmentLengthMultiplier = this.codeListC2C.getPropertyValue(
            UIProperty.Loads_C2C_SeismicLoadMultiplier,
            this.design.regionId ?? 0,
            this.design.connectionTypeId ?? 0,
            this.design.designStandardId
        );

        const numberGroupSeparator = getNumberGroupSeparator(this.localizationService.numberFormat(), this.userSettings);
        const numberDecimalSeparator = getNumberDecimalSeparator(this.localizationService.numberFormat(), this.userSettings);
        const precision = this.unitService.getPrecision(UnitType.None, UIProperty.Loads_C2C_SeismicLoadMultiplier);

        this.seismicLoadMultiplierProp = {
            id: 'design-consideration-seismic-load-multiplier',
            value: defaultDevelopmentLengthMultiplier.defaultValue ?? 0,
            title: this.translate('Agito.Hilti.C2C.Navigation.TabLoads.SeismicLoadMultiplier'),
            unit: UnitType.None,
            groupSeparator: numberGroupSeparator,
            decimalSeparator: numberDecimalSeparator,
            precision: precision,
            minValue: defaultDevelopmentLengthMultiplier.minValue,
            maxValue: defaultDevelopmentLengthMultiplier.maxValue,
            validators: [Validators.required],
            fixedDecimals: true
        } as NumericTextBoxProps;
    }

    private initHnaFireApplicationTypeDropdown() {
        this.fireApplicationTypeDropdown = {
            id: 'design-consideration-fire-application-type-c2c-dropdown',
            title: this.translate('Agito.Hilti.C2C.Navigation.TabLoads.FireApplicationType'),
            notSelectedText: this.translate('Agito.Hilti.C2C.DesignConsideration.FireApplicationType.Select'),
            validators: [this.requiredValidator]
        };

        this.updateHnaFireApplicationTypeDropdown();
    }

    private getHnaFireApplicationTypes(): ApplicationTypeC2C[] {
        const applicationTypes = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ApplicationTypesC2C] as ApplicationTypeC2C[];

        const allowedApplicationTypesJoints = [
            ApplicationType.WallOnSlab,
            ApplicationType.ColumnOnSlab,
            ApplicationType.SlabToWall,
            ApplicationType.BeamToWall,
            ApplicationType.BeamToColumn
        ];

        const allowedApplicationTypesExtensions = [
            ApplicationType.SlabToSlab,
            ApplicationType.BeamToBeam,
            ApplicationType.WallExtension,
            ApplicationType.ColumnExtension,
        ];

        let allowedApplicationTypes = this.design.isHnaExtensionsC2C ? allowedApplicationTypesExtensions : allowedApplicationTypesJoints;

        if (this.design.isHnaExtensionsC2C && this.data.isExtensionAtSupport) {
            allowedApplicationTypes = [
                ApplicationType.SlabToSlab,
                ApplicationType.BeamToBeam,
            ];
        }

        return applicationTypes.filter(app => allowedApplicationTypes.includes(app.id ?? 0));
    }

    private updateHnaFireApplicationTypeDropdown() {
        const fireApplicationTypes = this.getHnaFireApplicationTypes();

        this.fireApplicationTypeDropdown.items = fireApplicationTypes.map(
            (app) => {
                const appTranslationKey = `Agito.Hilti.C2C.CodeList.ApplicationTypeEntity.${app.displayKey}`;
                const translationKey = this.isExtensionAtSupportCheckbox.selectedValues?.has(true) ? `${appTranslationKey}.Support` : appTranslationKey;
                return {
                    id: `design-consideration-fire-application-type-c2c-dropdown-item-${app.id}`,
                    value: app.id,
                    text: this.translate(translationKey)
                };
            }
        );

        this.fireApplicationTypeDropdown.selectedValue = fireApplicationTypes.some((x) => x.id == this.data.selectedfireApplicationType)
            ? this.data.selectedfireApplicationType
            : fireApplicationTypes[0]?.id ?? 0;

        this.data.selectedfireApplicationType = this.fireApplicationTypeDropdown.selectedValue;
    }

    private getDefaultValueForApplicationTypeC2C(connectionTypeId: number, applicationTypesItems: Array<any>): number {
        const defaultValue = this.codeListC2C.getPropertyValue(UIProperty.General_C2C_ApplicationType, this.design.regionId ?? 0, connectionTypeId, this.design.designStandardId)?.defaultValue;
        return applicationTypesItems.some((x) => x.id == defaultValue && !x.disabled) ? defaultValue : applicationTypesItems.find((x) => !x.disabled)?.id;
    }

    private setSelectedConnectionType(type: ConnectionType) {
        if (this.data.selectedConnectionType != type) {
            this.data.selectedConnectionType = type;
            this.selectedConnectionTypeChange.emit(this.data.selectedConnectionType);
        }
    }

    private getConnectionTypesItems(connectionTypes: ConnectionTypeC2C[]): IGridImageSelectItem[] {
        return connectionTypes
            .filter(connType => (connType.applicationTypes?.length ?? 0) > 0)
            .map((conType) => ({
                image: conType.image,
                name: this.translate(conType.nameResourceKey ?? ''),
                id: conType.id,
                disabled: false,
                displayKey: conType.displayKey,
                hidden: conType.id === ConnectionType.ConcreteOverlay
            }));
    }

    private extensionAtSupportVisibility(): boolean {
        return this.data.selectedConnectionType === ConnectionType.Splices &&
            [ApplicationType.SlabToSlab, ApplicationType.BeamToBeam, ApplicationType.BeamToSlab, ApplicationType.Single].includes(this.data.selectedApplicationType);
    }

    private continuousXDirectionVisibility(): boolean {
        return [ApplicationType.SlabToSlab,
                ApplicationType.BeamToSlab,
                ApplicationType.ColumnToWall,
                ApplicationType.WallExtension,
                ApplicationType.WallOnSlab,
                ApplicationType.ColumnOnSlab,
                ApplicationType.SlabToWall,
                ApplicationType.BeamToWall
            ].includes(this.data.selectedApplicationType);
    }

    private initFireResistanceDropdown() {
        this.fireResistanceDropdown = {
            id: 'design-consideration-fire-resistance-rating-c2c-dropdown',
            title: this.translate('Agito.Hilti.C2C.DesignConsideration.FireResistanceRating.Title'),
            notSelectedText: this.translate('Agito.Hilti.C2C.DesignConsideration.FireResistanceRating.Select'),
            validators: [this.requiredValidator]
        };

        this.modifyFireResistanceItems();
    }

    private modifyFireResistanceItems() {
        const fireDurationItems = this.design.designData.designCodeListsC2C[DesignCodeListC2C.FireDuration] as FireDuration[];

        this.fireResistanceDropdown.items = fireDurationItems.map(
            (item) => {
                return {
                    id: `design-consideration-fire-resistance-rating-c2c-dropdown-item-${item.id}`,
                    value: item.id,
                    text: item.displayKey ?? ''
                };
            }
        );
    }

    public async save(form: NgForm) {
        if (!this.isFireResistanceDropdownDisabled && this.fireResistanceDropdown.selectedValue === undefined) {
            this.showRequiredLabel = true;
            return;
        }

        if (this.submitted || (form.enabled && !form.valid)) {
            return;
        }

        try {
            await this.calculateAndSaveDesign();
            this.close();
        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
            }

            this.submitted = false;
        } finally {
            this.submitted = true;
            this.trackDesignConsiderationData();
        }
    }

    private trackDesignConsiderationData(): void {
        if (!this.design.isPirEu) {
            return;
        }

        this.design.usageCounterC2C.DesignConsiderationPopUp = {
            connectionType: this.data.selectedConnectionType,
            applicationType: this.data.selectedApplicationType,
            isExtensionAtSupport: this.isExtensionAtSupportVisible && (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.isExtensionAtSupportCheckbox) ?? false),
            isXDirectionContinuous: this.isXDirectionContinuousVisible && (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.isXDirectionContinuousCheckbox) ?? false),
            fireType: this.fireResistanceRequirementRadio.selectedValue === FireResistanceRequirement.Yes,
            fireDuration: this.fireResistanceDropdown.selectedValue,
            seismicType: this.seismicConditionsRadio.selectedValue === SeismicConditions.Yes
        };
    }

    private async calculateAndSaveDesign() {
        this.firstPropertyValue(UIProperty.Option_C2C_DesignConsideration, true);
        this.setDesignModelProperties();
        await this.calculationService.calculateAsync(this.design);
    }

    private async firstPropertyValue(propertyId: number, value?: unknown) {
        this.design.addModelChangeNoCalculation(propertyId, true, value as object);
    }

    private setDesignModelProperties() {
        if (this.design.isPirEu) {
            this.design.model[UIProperty.General_C2C_ConnectionType] = this.data.selectedConnectionType;
            this.design.model[UIProperty.General_C2C_ApplicationType] = this.data.selectedApplicationType;

            if (this.isExtensionAtSupportVisible) {
                this.design.model[UIProperty.Application_C2C_Connection_IsExtensionAtSupport] = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.isExtensionAtSupportCheckbox);
            }

            if (this.isXDirectionContinuousVisible){
                this.design.model[UIProperty.Application_C2C_Connection_IsXDirectionContinuous] = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.isXDirectionContinuousCheckbox);
            }
        }

        this.setFireDesignModelProperties();
        this.setSeismicDesignModelProperties();
    }

    private setFireDesignModelProperties() {
        if (this.fireResistanceRequirementRadio.selectedValue === FireResistanceRequirement.Yes) {
            this.design.model[UIProperty.Loads_C2C_FireResistanceRequirement] = true;
            this.design.model[UIProperty.Loads_C2C_FireDuration] = this.fireResistanceDropdown.selectedValue;

            if (this.design.isPirHna) {
                this.design.model[UIProperty.Loads_C2C_FireApplicationType] = this.fireApplicationTypeDropdown.selectedValue;

                if (this.design.isHnaExtensionsC2C) {
                    this.design.model[UIProperty.Loads_C2C_FireApplicationExtensionAtSupport] = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.isExtensionAtSupportCheckbox);
                }
            }
        }
    }

    private setSeismicDesignModelProperties() {
        if (this.seismicConditionsRadio.selectedValue === SeismicConditions.Yes) {
            this.design.model[UIProperty.Loads_C2C_SeismicConditions] = true;

            if (this.design.isPirHna) {
                this.design.model[UIProperty.Loads_C2C_SeismicLoadMultiplier] = this.seismicLoadMultiplierProp.value;
            }
        }
    }
}
