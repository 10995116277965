import { Injectable } from '@angular/core';
import { UserServiceInjected } from '@profis-engineering/pe-ui-common/services/user.common';

import { DesignC2C } from '../../shared/entities/design-c2c';
import { IDetailedDisplayDesign } from '../../shared/entities/display-design';
import {
    DesignOptionsASbased
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options';
import {
    ConnectionType, DesignStandard, MaximumAreaReinforcement, MinimumAreaReinforcement,
    OptimizationStrategy, SpacingMaximum, StrutTies
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';

@Injectable({
    providedIn: 'root'
})
export class UserService extends UserServiceInjected<DesignC2C> {
    public mapDisplayDesignToDesign(displayDesign: IDetailedDisplayDesign) {
        this.design.designName = displayDesign.name;
        this.design.designMethodGroup = displayDesign.designMethodGroupC2C;
        this.design.templateName = displayDesign.templateName;
        this.design.projectId = displayDesign.projectId;
        this.design.projectName = displayDesign.projectName;
        this.design.unitLength = displayDesign.unitLength;
        this.design.unitArea = displayDesign.unitArea;
        this.design.unitStress = displayDesign.unitStress;
        this.design.unitForce = displayDesign.unitForce;
        this.design.unitMoment = displayDesign.unitMoment;
        this.design.unitTemperature = displayDesign.unitTemperature;
        this.design.unitForcePerLength = displayDesign.unitForcePerLength;
        this.design.unitMomentPerLength = displayDesign.unitMomentPerLength;
        this.design.unitDensity = displayDesign.unitDensity;
        this.design.unitAreaPerLength = displayDesign.unitAreaPerLength;

        this.design.partialReductionFactorFire = displayDesign.partialReductionFactorFire as number;
        this.design.partialReductionFactorSteel = displayDesign.partialReductionFactorSteel as number;
        this.design.partialReductionFactorConcrete = displayDesign.partialReductionFactorConcrete as number;
        this.design.connectionType = displayDesign.connectionType as ConnectionType;
        this.design.alphaCcC2C = displayDesign.alphaCcC2C as number;
        this.design.alphaCtC2C = displayDesign.alphaCtC2C as number;
        this.design.advancedRebarSettingsSteelStrainLimitC2C = displayDesign.advancedRebarSettingsSteelStrainLimit as number;
        this.design.k2 = displayDesign.concreteResistanceCompression as number;
        this.design.epsilonC2 = displayDesign.concreteEpsilonC2 as number;
        this.design.epsilonCU2 = displayDesign.concreteEpsionCu2 as number;
        this.design.minConcreteCover = displayDesign.minConcreteCover as number;
        this.design.epsilonUD = displayDesign.epsilonUd as number;
        this.design.partialSafetyFactorConcrete = displayDesign.partialSafetyFactorConcrete as number;
        this.design.partialSafetyFactorSteel = displayDesign.partialSafetyFactorSteel as number;
        this.design.e = displayDesign.E as number;
        this.design.existingReinforcementAlpha3 = displayDesign.existingReinforcementAlpha3 as number;
        this.design.transversePressure = displayDesign.transversePressure as number;
        this.design.minimumAreaReinforcement = displayDesign.minimumAreaReinforcement as MinimumAreaReinforcement;
        this.design.minimumAreaReinforcementInput = displayDesign.minimumAreaReinforcementInput as number;
        this.design.maximumAreaReinforcement = displayDesign.maximumAreaReinforcement as MaximumAreaReinforcement;
        this.design.maximumAreaReinforcementInput = displayDesign.maximumAreaReinforcementInput as number;
        this.design.spacingMaximum = displayDesign.spacingMaximum as SpacingMaximum;
        this.design.spacingMaximumInput = displayDesign.spacingMaximumInput as number;
        this.design.shearStrutAngle = displayDesign.shearStrutAngle as number;
        this.design.shearInclination = displayDesign.shearInclination as number;
        this.design.strutTies = displayDesign.strutTies as StrutTies;
        this.design.strutTiesInput = displayDesign.strutTiesInput as number;
        this.design.optimizationStrategy = displayDesign.optimizationStrategy as OptimizationStrategy;
        this.design.gammaRd = displayDesign.gammaRd as number;
        this.design.useGermanNationalAnnex = displayDesign.useGermanNationalAnnex ?? false;
        this.design.useDenmarkNationalAnnex = displayDesign.useDenmarkNationalAnnex ?? false;
        this.design.metricEquation = displayDesign.metricEquation as number;

        if (displayDesign.designStandardC2C?.id == DesignStandard.ASBased)
            this.mapASbasedOptions(displayDesign.designOptionsAs);
    }

    private mapASbasedOptions(optionsAs: DesignOptionsASbased | undefined) {
        if (!optionsAs)
            return;

        this.design.AS_steelStress = optionsAs.safetyFactors.steelStress as number;
        this.design.AS_concreteStressCrossSection = optionsAs.safetyFactors.concreteStressCrossSection as number;
        this.design.AS_concreteStressShearInterface = optionsAs.safetyFactors.concreteStressShearInterface as number;
        this.design.AS_epsilonC2 = optionsAs.concreteMaterialOptions.epsilonC2 as number;
        this.design.AS_epsilonCu2 = optionsAs.concreteMaterialOptions.epsilonCU2 as number;
        this.design.AS_beta = optionsAs.concreteMaterialOptions.beta as number;
        this.design.AS_minConcreteCover = optionsAs.concreteMaterialOptions.minConcreteCover as number;
        this.design.AS_epsilonUd = optionsAs.steelMaterialOptions.epsilonUd as number;
        this.design.AS_steelE = optionsAs.steelMaterialOptions.e as number;
        this.design.AS_factorK4 = optionsAs.rebarCalculationParameters.factorK4 as number;
        this.design.AS_factorK6 = optionsAs.rebarCalculationParameters.factorK6 as number;
        this.design.AS_transversePressure = optionsAs.rebarCalculationParameters.transversePressure as number;
        this.design.AS_minimumAreaReinforcement = optionsAs.rebarCalculationParameters.minimumAreaReinforcement;
        this.design.AS_minimumAreaReinforcementInput = optionsAs.rebarCalculationParameters.minimumAreaReinforcementInput as number;
        this.design.AS_maximumAreaReinforcement = optionsAs.rebarCalculationParameters.maximumAreaReinforcement;
        this.design.AS_maximumAreaReinforcementInput = optionsAs.rebarCalculationParameters.maximumAreaReinforcementInput as number;
        this.design.AS_spacingMaximum = optionsAs.rebarCalculationParameters.spacingMaximum;
        this.design.AS_spacingMaximumInput = optionsAs.rebarCalculationParameters.spacingMaximumInput as number;
        this.design.AS_optimizationStrategy = optionsAs.optimizationStrategyOptions.optimizationStrategy;
        this.design.AS_strutAngleNewConcrete = optionsAs.interfaceShearCalculationParameters.strutAngleNewConcrete as number;
        this.design.AS_shearTensileAngle = optionsAs.interfaceShearCalculationParameters.shearTensileAngle as number;
    }
}
