import { UIProperty } from '../entities/code-lists/UI-property';
import { ProjectCodeList } from '../enums/project-code-list';
import { CodeListServiceBase as CodeListServiceC2C } from '../services/code-list.service.base';

export function defaultUiPropertyValue(uiProperty: number, codeListC2C: CodeListServiceC2C, regionId: number, designStandardId?: number, connectionTypeId?: number, applicationTypeId?: number, designMethodGroupId?: number): number | undefined {
    const c2cUIProperties = codeListC2C.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as UIProperty[];
    const propertyValues = c2cUIProperties.find((property) => property.id == uiProperty) as UIProperty;

    let filteredProperties = [];

    let propertyValuesRegion = (propertyValues.propertyValues ?? []).filter((property) => property.regionId == regionId);
    if (!propertyValuesRegion.length) {
        propertyValuesRegion = (propertyValues.propertyValues ?? []).filter((property) => property.regionId == 0);
    }
    filteredProperties = propertyValuesRegion;

    if (connectionTypeId) {
        let propertyValueConnection = filteredProperties.filter((property) => property.connectionTypeId == connectionTypeId);
        if (!propertyValueConnection.length) {
            propertyValueConnection = filteredProperties.filter((property) => property.connectionTypeId == 0);
        }
        filteredProperties = propertyValueConnection;
    }

    if (applicationTypeId) {
        let propertyValueApplication = filteredProperties.filter((property) => property.applicationTypeId == applicationTypeId);
        if (!propertyValueApplication.length) {
            propertyValueApplication = filteredProperties.filter((property) => property.applicationTypeId == 0);
        }
        filteredProperties = propertyValueApplication;
    }

    if (designStandardId) {
        let propertyValueStandard = filteredProperties.filter((property) => property.designStandardId == designStandardId);
        if (!propertyValueStandard.length) {
            propertyValueStandard = filteredProperties.filter((property) => property.designStandardId == 0);
        }
        filteredProperties = propertyValueStandard;
    }

    if (designMethodGroupId) {
        let propertyValueStandard = filteredProperties.filter((property) => property.designMethodGroupId == designMethodGroupId);
        if (!propertyValueStandard.length) {
            propertyValueStandard = filteredProperties.filter((property) => property.designMethodGroupId == 0);
        }
        filteredProperties = propertyValueStandard;
    }

    return filteredProperties[0]?.defaultValue;
}

export function sanitizePropertyValueForJson<T>(value: T): T | string | undefined | null {
    if (value == null) {
        return null;
    }

    if (typeof value === 'number') {

        // Handle Infinity
        if (value == Number.POSITIVE_INFINITY) {
            return '∞';
        }
        if (value == Number.NEGATIVE_INFINITY) {
            return '-∞';
        }
    }

    return value;
}
