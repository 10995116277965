import { Injectable } from '@angular/core';
import {
    FeatureFlagFromPeUi, FeaturesVisibilityServiceInjected
} from '@profis-engineering/pe-ui-common/services/feature-visibility.common';

export type FeatureFlag =
    FeatureFlagFromPeUi |
    'C2C_TR069Seismic' |
    'C2C_HnaFire' |
    'C2C_MultiLoadPirEu' |
    'C2C_UKTA' |
    'C2C_HnaDesignMethods' |
    'C2C_EnableAciMeta' |
    'C2C_WoodModuleESPT' |
    'C2C_WoodModuleE2' |
    'C2C_ThinOverlayRegions' |
    'C2C_ThinOverlayNewRegions' |
    'C2C_HiltiTR066Applications' |
    'C2C_DenmarkNA' |
    'LD_AutomatedPage';

@Injectable({
    providedIn: 'root'
})
export class FeaturesVisibilityService extends FeaturesVisibilityServiceInjected<FeatureFlag> {
}
