<div *ngIf="isLoaded && step == stepEnum.basic">
    <div class="section no-margin">
        <div class="section-row">
            <span class="local-steel-material-disclaimer all"
                l10n="Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer"></span>
        </div>
    </div>

    <div class="section data"
        [class.no-margin]="collapseRegion.MethodAndApprovals">
        <p class="title-strike title" [class.no-margin]="collapseRegion.MethodAndApprovals">
            <span l10n="Agito.Hilti.Profis3.C2CCalculation"></span>
            <pe-collapse class="section-collapse" [container]="'section-methods-approvals'"
                [collapsed]="collapseRegion.MethodAndApprovals"
                (collapsedChange)="collapseRegion.MethodAndApprovals = $any($event).detail">
            </pe-collapse>
        </p>

        <div class="section-row section-methods-approvals"
            *ngIf="!collapseRegion.MethodAndApprovals">
            <div class="section-column separator design-standard">
                <!-- Design Standard -->
                <div class="content">
                    <div class="control-column length-large-dropdown">
                        <pe-dropdown [id]="projectDesignStandardDropdown.id"
                            [title]="projectDesignStandardDropdown.title"
                            [notSelectedText]="projectDesignStandardDropdown.notSelectedText"
                            [items]="projectDesignStandardDropdown.items"
                            [selectedValue]="projectDesignStandardDropdown.selectedValue"
                            (selectedValueChange)="onDesignStandardDropdownSelectedValueChange($any($event).detail)"
                            [validators]="projectDesignStandardDropdown.validators"
                            [disabled]="submitted || addEditType == AddEditType.edit || designStandard?.disabled">
                        </pe-dropdown>
                    </div>

                    <div class="design-standard-note" *ngIf="selectedDesignStandardDescription != null"
                        [l10n]="selectedDesignStandardDescription"></div>
                </div>
            </div>

            <div class="section-column separator design-method-group">
                <!-- Design Method -->
                <div class="content" *ngIf="isDesignMethodVisible">
                    <div class="control-column length-large-dropdown">
                        <pe-dropdown [id]="projectDesignMethodDropdown.id"
                            [title]="projectDesignMethodDropdown.title"
                            [notSelectedText]="projectDesignMethodDropdown.notSelectedText"
                            [items]="projectDesignMethodDropdown.items"
                            [selectedValue]="projectDesignMethodDropdown.selectedValue"
                            (selectedValueChange)="onDesignMethodDropdownSelectedValueChange($any($event).detail)"
                            [validators]="[requiredValidator]" [disabled]="submitted">
                        </pe-dropdown>
                    </div>

                    <div class="design-method-note" *ngIf="selectedDesignMethodDescription != null"
                        [l10n]="selectedDesignMethodDescription"></div>
                </div>

                <div class="content" *ngIf="isUseGermanNationalAnnexVisible">
                    <pe-checkbox-button *ngIf="isUseGermanNationalAnnexVisible"
                        [items]="useGermanNationalAnnexCheckbox.items"
                        [selectedValues]="useGermanNationalAnnexCheckbox.selectedValues"
                        (selectedValuesChange)="useGermanNationalAnnexCheckbox.selectedValues = $any($event).detail"
                        [disabled]="submitted">
                    </pe-checkbox-button>
                </div>

                <div class="content" *ngIf="isUseDenmarkNationalAnnexVisible">
                    <pe-checkbox-button
                        [items]="useDenmarkNationalAnnexCheckbox.items"
                        [selectedValues]="useDenmarkNationalAnnexCheckbox.selectedValues"
                        (selectedValuesChange)="useDenmarkNationalAnnexCheckbox.selectedValues = $any($event).detail"
                        [disabled]="submitted">
                    </pe-checkbox-button>
                </div>
            </div>

            <div class="section-column separator technical-data">
                <!-- Technical Data - CHECK if this is needed -->
            </div>
        </div>
    </div>

    <div class="select-connection-application-add-edit-design">
        <c2c-select-connection-application [data]="selectConnectionApplication" [overlayOnly]="isOverlayOnly"
            (selectedConnectionTypeChange)="onSelectedConnectionTypeChange($any($event).detail)"
            (selectedApplicationTypeChange)="onSelectedApplicationTypeChange($any($event).detail)">
        </c2c-select-connection-application>
    </div>

    <div class="section units" [class.no-margin]="collapseRegion.UnitsAndParams">
        <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams">
            <span l10n="Agito.Hilti.Profis3.AddEditDesign.Units"></span>

            <pe-collapse class="section-collapse" [id]="'section-units-params'" [container]="'section-units-params'"
                [collapsed]="collapseRegion.UnitsAndParams"
                (collapsedChange)="collapseRegion.UnitsAndParams = $any($event).detail">
            </pe-collapse>
        </p>

        <div class="control-row section-units-params">
            <div class="control-column length-dropdown">
                <pe-dropdown [id]="lengthDropdown.id" [title]="lengthDropdown.title" [items]="lengthDropdown.items"
                    [selectedValue]="lengthDropdown.selectedValue"
                    (selectedValueChange)="lengthDropdown.selectedValue = $any($event).detail; onLengthDropdownChange()"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column area-dropdown">
                <pe-dropdown [id]="areaDropdown.id" [title]="areaDropdown.title" [items]="areaDropdown.items"
                    [selectedValue]="areaDropdown.selectedValue"
                    (selectedValueChange)="areaDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column stress-dropdown">
                <pe-dropdown [id]="stressDropdown.id" [title]="stressDropdown.title" [items]="stressDropdown.items"
                    [selectedValue]="stressDropdown.selectedValue"
                    (selectedValueChange)="stressDropdown.selectedValue = $any($event).detail; onStressDropdownChange()"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column force-dropdown">
                <pe-dropdown [id]="forceDropdown.id" [title]="forceDropdown.title" [items]="forceDropdown.items"
                    [selectedValue]="forceDropdown.selectedValue"
                    (selectedValueChange)="forceDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column moment-dropdown">
                <pe-dropdown [id]="momentDropdown.id" [title]="momentDropdown.title" [items]="momentDropdown.items"
                    [selectedValue]="momentDropdown.selectedValue"
                    (selectedValueChange)="momentDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column temperature-dropdown">
                <pe-dropdown [id]="temperatureDropdown.id" [title]="temperatureDropdown.title"
                    [items]="temperatureDropdown.items"
                    [selectedValue]="temperatureDropdown.selectedValue"
                    (selectedValueChange)="temperatureDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column force-per-length-dropdown">
                <pe-dropdown [id]="forcePerLengthDropdown.id" [title]="forcePerLengthDropdown.title"
                    [items]="forcePerLengthDropdown.items"
                    [selectedValue]="forcePerLengthDropdown.selectedValue"
                    (selectedValueChange)="forcePerLengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column moment-per-length-dropdown">
                <pe-dropdown [id]="momentPerLengthDropdown.id" [title]="momentPerLengthDropdown.title"
                    [items]="momentPerLengthDropdown.items"
                    [selectedValue]="momentPerLengthDropdown.selectedValue"
                    (selectedValueChange)="momentPerLengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column density-dropdown">
                <pe-dropdown [id]="densityDropdown.id" [title]="densityDropdown.title" [items]="densityDropdown.items"
                    [selectedValue]="densityDropdown.selectedValue"
                    (selectedValueChange)="densityDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column area-per-length-dropdown" *ngIf="isAreaPerLengthDropdownAvailable">
                <pe-dropdown [id]="areaPerLengthDropdown.id" [title]="areaPerLengthDropdown.title"
                    [items]="areaPerLengthDropdown.items"
                    [selectedValue]="areaPerLengthDropdown.selectedValue"
                    (selectedValueChange)="areaPerLengthDropdown.selectedValue = $any($event).detail; onAreaPerLengthDropdownChange()"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column metric-equations-dropdown"
                *ngIf="isMetricEquationDropdownAvailable">
                <pe-dropdown [id]="metricEquationDropdown.id" [title]="metricEquationDropdown.title"
                    [items]="metricEquationDropdown.items"
                    [selectedValue]="metricEquationDropdown.selectedValue"
                    (selectedValueChange)="metricEquationDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>
        </div>
    </div>

    <div class="section reduction-factors" *ngIf="!isHNAC2C && !isPIREU() && !isPIRAUS()"
        [class.no-margin]="collapseRegion.ReductionFactors">
        <p class="title-strike title" [class.no-margin]="collapseRegion.ReductionFactors">
            <span l10n="Agito.Hilti.Profis3.AddEditDesign.ReductionFactors"></span>

            <pe-collapse class="section-collapse" [id]="'section-reduction-factors'"
                [container]="'section-reduction-factors'" [collapsed]="collapseRegion.ReductionFactors"
                (collapsedChange)="collapseRegion.ReductionFactors = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-reduction-factors">
            <c2c-reduction-factors-inputs [data]="reductionFactorsInputsData" [regionId]="selectedRegionId"
                [disabled]="submitted">
            </c2c-reduction-factors-inputs>
        </div>
    </div>

    <div class="section safety-factors" *ngIf="isPIREU() || isPIRAUS()"
        [class.no-margin]="collapseRegion.SafetyFactor">
        <p class="title-strike title" [class.no-margin]="collapseRegion.SafetyFactor">
            <span l10n="Agito.Hilti.Profis3.AddEditDesign.ReductionFactors"></span>

            <pe-collapse class="section-collapse" [id]="'section-safety-factors'" [container]="'section-safety-factors'"
                [collapsed]="collapseRegion.SafetyFactor"
                (collapsedChange)="collapseRegion.SafetyFactor = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-safety-factors">
            <c2c-safety-factors-inputs [data]="safetyFactorsInputsData" [regionId]="selectedRegionId" [disabled]="submitted" [designMethodGroupId]="designMethodGroupId">
            </c2c-safety-factors-inputs>
        </div>
    </div>

    <div class="section concrete-material" *ngIf="isPIREU() || isPIRAUS()"
        [class.no-margin]="collapseRegion.ReductionFactors">
        <p class="title-strike title" [class.no-margin]="collapseRegion.ReductionFactors">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.ConcreteMaterial"></span>

            <pe-collapse class="section-collapse" [id]="'section-concrete-material'"
                [container]="'section-concrete-material'" [collapsed]="collapseRegion.ConcreteMaterial"
                (collapsedChange)="collapseRegion.ConcreteMaterial = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-concrete-material">
            <c2c-concrete-material-inputs [data]="concreteMaterialInputsData" [regionId]="selectedRegionId" [designMethodGroupId]="designMethodGroupId"
                [disabled]="submitted">
            </c2c-concrete-material-inputs>
        </div>
    </div>

    <div class="section steel-material" *ngIf="isPIREU() || isPIRAUS()"
        [class.no-margin]="collapseRegion.ReductionFactors">
        <p class="title-strike title" [class.no-margin]="collapseRegion.ReductionFactors">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.SteelMaterial"></span>

            <pe-collapse class="section-collapse" [id]="'section-steel-material'" [container]="'section-steel-material'"
                [collapsed]="collapseRegion.SteelMaterial"
                (collapsedChange)="collapseRegion.SteelMaterial = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-steel-material">
            <c2c-steel-material-inputs [data]="steelMaterialInputsData" [regionId]="selectedRegionId" [disabled]="submitted" [designMethodGroupId]="designMethodGroupId">
            </c2c-steel-material-inputs>
        </div>
    </div>

    <div class="section rebar-calculation-parameters" *ngIf="isPIREU() || isPIRAUS()"
        [class.no-margin]="collapseRegion.RebarCalculation">
        <p class="title-strike title" [class.no-margin]="collapseRegion.RebarCalculation">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.RebarCalculation"></span>

            <pe-collapse class="section-collapse" [id]="'section-rebar-calculation'"
                [container]="'section-rebar-calculation'" [collapsed]="collapseRegion.RebarCalculation"
                (collapsedChange)="collapseRegion.RebarCalculation = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-rebar-calculation">
            <c2c-rebar-calculation-parameters-inputs [data]="rebarCalculationInputsData" [regionId]="selectedRegionId" [designMethodGroupId]="designMethodGroupId"
                [disabled]="submitted">
            </c2c-rebar-calculation-parameters-inputs>
        </div>
    </div>

    <div class="section interface-shear-calculation-parameters" *ngIf="isPIREU() || isPIRAUS()"
        [class.no-margin]="collapseRegion.InterfaceShearCalculation">
        <p class="title-strike title" [class.no-margin]="collapseRegion.InterfaceShearCalculation">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.InterfaceShearCalculation"></span>

            <pe-collapse class="section-collapse" [id]="'section-interface-shear-calculation'"
                [container]="'section-interface-shear-calculation'"
                [collapsed]="collapseRegion.InterfaceShearCalculation"
                (collapsedChange)="collapseRegion.InterfaceShearCalculation = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-interface-shear-calculation">
            <c2c-interface-shear-calculation-parameters [data]="interfaceShearCalculationInputsData"
                [regionId]="selectedRegionId" [disabled]="submitted" [designMethodGroupId]="designMethodGroupId">
            </c2c-interface-shear-calculation-parameters>
        </div>
    </div>

    <div class="section strut-and-ties-model-calculation-parameter" *ngIf="isPIREU() || isPIRAUS()"
        [hidden]="!isPIREU() && !isPirAusEn()"
        [class.no-margin]="collapseRegion.StrutTiesModelCalculation">
        <p class="title-strike title" [class.no-margin]="collapseRegion.StrutTiesModelCalculation">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.StrutTiesModelCalculation"></span>

            <pe-collapse class="section-collapse" [id]="'section-strut-and-ties-model-calculation-parameter'"
                [container]="'section-strut-and-ties-model-calculation-parameter'"
                [collapsed]="collapseRegion.StrutTiesModelCalculation"
                (collapsedChange)="collapseRegion.StrutTiesModelCalculation = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-strut-and-ties-model-calculation-parameter">
            <c2c-strut-ties-model-calculation [data]="strutTiesInputsData" [disabled]="submitted" [regionId]="selectedRegionId">
            </c2c-strut-ties-model-calculation>
        </div>
    </div>

    <div class="section optimization-strategy-parameter" *ngIf="isPIREU() || isPIRAUS()"
        [class.no-margin]="collapseRegion.OptimizationStrategy">
        <p class="title-strike title" [class.no-margin]="collapseRegion.OptimizationStrategy">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.OptimizationStrategy"></span>

            <pe-collapse class="section-collapse" [id]="'section-optimization-strategy-parameter'"
                [container]="'section-optimization-strategy-parameter'"
                [collapsed]="collapseRegion.OptimizationStrategy"
                (collapsedChange)="collapseRegion.OptimizationStrategy = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-optimization-strategy-parameter">
            <c2c-optimization-strategy-inputs [data]="optimizationStrategyInputsData" [disabled]="submitted" [regionId]="selectedRegionId" [designMethodGroupId]="designMethodGroupId">
            </c2c-optimization-strategy-inputs>
        </div>
    </div>

    <div class="section advanced-rebar-settings" *ngIf="isPirHnaC2C"
        [class.no-margin]="collapseRegion.AdvancedRebarSettings">
        <p class="title-strike title" [class.no-margin]="collapseRegion.AdvancedRebarSettings">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.AdvancedRebarSettings"></span>

            <pe-collapse class="section-collapse" [id]="'section-advanced-rebar-settings'"
                [container]="'section-advanced-rebar-settings'" [collapsed]="collapseRegion.AdvancedRebarSettings"
                (collapsedChange)="collapseRegion.AdvancedRebarSettings = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-advanced-rebar-settings">
            <c2c-advanced-rebar-settings-inputs [data]="advancedRebarSettingsInputsData" [disabled]="submitted">
            </c2c-advanced-rebar-settings-inputs>
        </div>
    </div>

    <div class="section concrete-material-settings" *ngIf="isPirHnaC2C"
        [class.no-margin]="collapseRegion.ConcreteMaterialSettings">
        <p class="title-strike title" [class.no-margin]="collapseRegion.ConcreteMaterialSettings">
            <span l10n="Agito.Hilti.C2C.AddEditDesign.ConcreteMaterial"></span>

            <pe-collapse class="section-collapse" [id]="'section-concrete-material-settings'"
                [container]="'section-concrete-material-settings'" [collapsed]="collapseRegion.ConcreteMaterialSettings"
                (collapsedChange)="collapseRegion.ConcreteMaterialSettings = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-concrete-material-settings">
            <c2c-concrete-material-settings-inputs [data]="concreteMaterialSettingsInputsData" [disabled]="submitted">
            </c2c-concrete-material-settings-inputs>
        </div>
    </div>
</div>

<div *ngIf="isLoaded && step == stepEnum.connectionApplication">
    <div class="select-connection-application-quick-start">
        <c2c-select-connection-application
            [data]="selectConnectionApplication"
            [overlayOnly]="isOverlayOnly"
            (selectedConnectionTypeChange)="onSelectedConnectionTypeChange($any($event).detail)"
            (selectedApplicationTypeChange)="onSelectedApplicationTypeChange($any($event).detail)"
            [standalone]="true">
        </c2c-select-connection-application>
    </div>
</div>
