import { Subscription } from 'rxjs';

import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import { IUserMenuFavorites } from '@profis-engineering/pe-ui-common/entities/favorites';
import {
    IModuleInitialData, IModulePreInitialData
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    AuthenticationServiceBase
} from '@profis-engineering/pe-ui-common/services/authentication.common';
import { BrowserServiceBase } from '@profis-engineering/pe-ui-common/services/browser.common';
import { ChangesServiceBase } from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    CommonTrackingServiceBase
} from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { DateTimeServiceBase } from '@profis-engineering/pe-ui-common/services/date-time.common';
import {
    DesignTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { FavoritesServiceBase } from '@profis-engineering/pe-ui-common/services/favorites.common';
import {
    FeaturesVisibilityInfoServiceBase
} from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';
import { Menu2dServiceBase } from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { MenuServiceBase } from '@profis-engineering/pe-ui-common/services/menu.common';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import { OfflineServiceBase } from '@profis-engineering/pe-ui-common/services/offline.common';
import { QueryServiceBase } from '@profis-engineering/pe-ui-common/services/query.common';
import {
    RegionOrderServiceBase
} from '@profis-engineering/pe-ui-common/services/region-order.common';
import {
    ReportTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/report-template.common';
import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';
import { TooltipServiceBase } from '@profis-engineering/pe-ui-common/services/tooltip.common';
import { TourServiceBase } from '@profis-engineering/pe-ui-common/services/tour.common';
import {
    TrimbleConnectServiceBase
} from '@profis-engineering/pe-ui-common/services/trimble-connect.common';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';

import { environment } from '../../../environments/environmentC2C';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { UserSettingsC2C } from '../../../shared/entities/user-settings';
import { CodeListServiceBase } from '../../../shared/services/code-list.service.base';
import { ReportServiceBase } from '../../../shared/services/report.common';
import { SignalRServiceC2CBase } from '../../../shared/services/signalr.service.base';
import { SharedEnvironmentData } from '../../entities/sharedEnvironment';
import { ApiService } from '../../services/api.service';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BrowserService } from '../../services/browser.service';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { ChangesService } from '../../services/changes.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { DateTimeService } from '../../services/date-time.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentServiceBaseC2C, DocumentServiceC2C } from '../../services/document.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { GuidService } from '../../services/guid.service';
import { ImportService } from '../../services/import.service';
import { InitialDataService } from '../../services/initial-data.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { MathService } from '../../services/math.service';
import { Menu2dService } from '../../services/menu-2d.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { QueryService } from '../../services/query.service';
import { RegionOrderService } from '../../services/region-order.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { ReportService } from '../../services/report.service';
import { RoutingService } from '../../services/routing.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { TooltipService } from '../../services/tooltip.service';
import { TourService } from '../../services/tour.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

@Component({
    template: '',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UiC2CInitComponent implements OnInit, OnDestroy {
    //#region Input
    @Input()
    public localizationService!: LocalizationServiceBase;

    @Input()
    public mathService!: MathServiceBase;

    @Input()
    public tooltipService!: TooltipServiceBase;

    @Input()
    public numberService!: NumberServiceBase;

    @Input()
    public commonCodeListService!: CommonCodeListServiceBase;

    @Input()
    public codeListService!: CodeListServiceBase;

    @Input()
    public authenticationService!: AuthenticationServiceBase;

    @Input()
    public signalRService!: SignalRServiceC2CBase;

    @Input()
    public unitService!: UnitServiceBase;

    @Input()
    public userService!: UserServiceBase<DesignC2C>;

    @Input()
    public userSettingsService!: UserSettingsServiceBase<UserSettingsC2C>;

    @Input()
    public modalService!: ModalServiceBase;

    @Input()
    public offlineService!: OfflineServiceBase;

    @Input()
    public guidService!: GuidService;

    @Input()
    public featureVisibilityInfoService!: FeaturesVisibilityInfoServiceBase;

    @Input()
    public featureVisibilityService!: FeaturesVisibilityService;

    @Input()
    public licenseService!: LicenseService;

    @Input()
    public routingService!: RoutingServiceBase;

    @Input()
    public tourService!: TourServiceBase;

    @Input()
    public documentService!: DocumentServiceBaseC2C;

    @Input()
    public designTemplateService!: DesignTemplateServiceBase;

    @Input()
    public queryService!: QueryServiceBase;

    @Input()
    public importService!: ImportServiceBase;

    @Input()
    public apiService!: ApiServiceBase;

    @Input()
    public browserService!: BrowserServiceBase;

    @Input()
    public trimbleConnectService!: TrimbleConnectServiceBase;

    @Input()
    public dateTimeService!: DateTimeServiceBase;

    @Input()
    public loggerService!: LoggerServiceBase;

    @Input()
    public reportTemplateService!: ReportTemplateServiceBase;

    @Input()
    public reportService!: ReportServiceBase;

    @Input()
    public menuService!: MenuServiceBase;

    @Input()
    public menu2dService!: Menu2dServiceBase;

    @Input()
    public changesService!: ChangesServiceBase;

    @Input()
    public favoritesService!: FavoritesServiceBase;

    @Input()
    public regionOrderService!: RegionOrderServiceBase;

    @Input()
    public commonTrackingService!: CommonTrackingServiceBase;

    @Input()
    public sharedEnvironmentData!: SharedEnvironmentData;
    //#endregion

    @Output()
    public preInit = new EventEmitter<IModulePreInitialData | (() => IModulePreInitialData | Promise<IModulePreInitialData>)>();

    @Output()
    public init = new EventEmitter<IModuleInitialData>();

    @Output()
    public update = new EventEmitter<IModuleInitialData>();

    private featureVisibilityServiceSubscription?: Subscription;
    private favoritesServiceDataChangeSubscription?: Subscription;
    private initFailed = false;

    constructor(
        private readonly localizationSvc: LocalizationService,
        private readonly mathSvc: MathService,
        private readonly tooltipSvc: TooltipService,
        private readonly numberSvc: NumberService,
        private readonly commonCodeListSvc: CommonCodeListService,
        private readonly codeListSvc: CodeListService,
        private readonly calculationSvc: CalculationServiceC2C,
        private readonly authenticationSvc: AuthenticationService,
        private readonly unitSvc: UnitService,
        private readonly userSvc: UserService,
        private readonly userSettingsSvc: UserSettingsService,
        private readonly initialDataService: InitialDataService,
        private readonly modalSvc: ModalService,
        private readonly offlineSvc: OfflineService,
        private readonly featureVisibilityInfoSvc: FeaturesVisibilityInfoService,
        private readonly featureVisibilitySvc: FeaturesVisibilityService,
        private readonly licenseSvc: LicenseService,
        private readonly routingSvc: RoutingService,
        private readonly tourSvc: TourService,
        private readonly documentSvc: DocumentServiceC2C,
        private readonly designTemplateSvc: DesignTemplateService,
        private readonly querySvc: QueryService,
        private readonly importSvc: ImportService,
        private readonly apiSvc: ApiService,
        private readonly browserSvc: BrowserService,
        private readonly trimbleConnectSvc: TrimbleConnectService,
        private readonly dateTimeSvc: DateTimeService,
        private readonly loggerSvc: LoggerService,
        private readonly reportTemplateSvc: ReportTemplateService,
        private readonly reportSvc: ReportService,
        private readonly menuSvc: MenuService,
        private readonly menu2dSvc: Menu2dService,
        private readonly changesSvc: ChangesService,
        private readonly favoritesSvc: FavoritesService,
        private readonly regionOrderSvc: RegionOrderService,
        private readonly commonTrackingSvc: CommonTrackingService,
        private readonly sharedEnvironmentSvc: SharedEnvironmentService,
        private readonly applicationProviderService: ApplicationProviderService
    ) { }

    public ngOnInit(): void {
        this.preInit.emit(() => {
            try {
                const preInitData = this.initialDataService.getPreInitialData();

                preInitData.initialDataLoadedEvent = async () => {
                    if (!environment.c2cEnabled || this.initFailed) {
                        return;
                    }

                    this.preInitServices();
                    await this.loadData();
                    this.initServices();

                    this.localizationService.localizationChange.subscribe(() => {
                        this.applicationProviderService.updateDesignTypeTranslations();
                        this.update.emit(this.initialDataService.updateModuleData());
                    });

                    this.init.emit(this.initialDataService.getInitialData());
                    this.applicationProviderService.updateDesignTypeTranslations();
                };

                return preInitData;
            }
            catch (error) {
                this.handleError(error, false);
                throw error;
            }
        });
    }

    /** Called before data is loaded */
    private preInitServices() {
        this.localizationSvc.setBaseService(this.localizationService);
        this.mathSvc.setBaseService(this.mathService);
        this.tooltipSvc.setBaseService(this.tooltipService);
        this.numberSvc.setBaseService(this.numberService);
        this.commonCodeListSvc.setBaseService(this.commonCodeListService);
        this.codeListSvc.setBaseService(this.codeListService);
        this.authenticationSvc.setBaseService(this.authenticationService);
        this.unitSvc.setBaseService(this.unitService);
        this.userSvc.setBaseService(this.userService);
        this.documentSvc.setBaseService(this.documentService);
        this.modalSvc.setBaseService(this.modalService);
        this.offlineSvc.setBaseService(this.offlineService);
        this.featureVisibilityInfoSvc.setBaseService(this.featureVisibilityInfoService);
        this.initFeatureVisibilityService();
        this.licenseSvc.setBaseService(this.licenseService);
        this.routingSvc.setBaseService(this.routingService);
        this.tourSvc.setBaseService(this.tourService);
        this.designTemplateSvc.setBaseService(this.designTemplateService);
        this.querySvc.setBaseService(this.queryService);
        this.importSvc.setBaseService(this.importService);
        this.apiSvc.setBaseService(this.apiService);
        this.browserSvc.setBaseService(this.browserService);
        this.trimbleConnectSvc.setBaseService(this.trimbleConnectService);
        this.dateTimeSvc.setBaseService(this.dateTimeService);
        this.loggerSvc.setBaseService(this.loggerService);
        this.reportTemplateSvc.setBaseService(this.reportTemplateService);
        this.reportSvc.setBaseService(this.reportService);
        this.menuSvc.setBaseService(this.menuService);
        this.menu2dSvc.setBaseService(this.menu2dService);
        this.changesSvc.setBaseService(this.changesService);
        this.favoritesSvc.setBaseService(this.favoritesService);

        this.favoritesServiceDataChangeSubscription = this.favoritesSvc.dataChange.subscribe({
            next: (data: object) => {
                if (this.initFailed) {
                    return;
                }

                this.safeInvoke(() => {
                    const c2cData = data as IUserMenuFavorites;
                    this.favoritesSvc.initFavorites(c2cData);
                });
            },
            error: (err) => this.handleError(err)
        });
        this.regionOrderSvc.setBaseService(this.regionOrderService);
        this.commonTrackingSvc.setBaseService(this.commonTrackingService);
        this.sharedEnvironmentSvc.initialize(this.sharedEnvironmentData);
        this.userSettingsSvc.setBaseService(this.userSettingsService);
    }

    /** Called after data is loaded */
    private initServices() {
        this.userSettingsSvc.initUserSettingsValidation();
    }

    private async loadData() {
        const loaded = await Promise.allSettled([
            this.localizationSvc.loadTranslations(),
            this.initialDataService.initCodeList({ supressErrorMessage: true })
        ]);

        for (const result of loaded) {
            if (result.status === 'rejected') {
                throw result.reason;
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.featureVisibilityServiceSubscription != null) {
            this.featureVisibilityServiceSubscription.unsubscribe();
            this.featureVisibilityServiceSubscription = undefined;
        }

        // unsubscribe from favoritesService.dataChange subject.
        if (this.favoritesServiceDataChangeSubscription != null) {
            this.favoritesServiceDataChangeSubscription.unsubscribe();
            this.favoritesServiceDataChangeSubscription = undefined;
        }
    }

    private handleError(error: any, logError = true) {
        this.initFailed = true;

        if (logError) {
            console.error(error);
        }
    }

    private safeInvoke(fn: () => void) {
        try {
            fn();
        }
        catch (error) {
            this.handleError(error);
        }
    }

    private initFeatureVisibilityService() {
        this.featureVisibilitySvc.setBaseService(this.featureVisibilityService);
        this.featureVisibilityServiceSubscription = this.featureVisibilitySvc.init.subscribe({
            next: (initialized) => {
                if (!initialized || this.initFailed) {
                    return;
                }

                this.initializeDemoFeatures();
            },
            error: (error) => this.handleError(error)
        });
    }

    private initializeDemoFeatures() {
        // this is a pre prep for the demo features
        this.featureVisibilitySvc.registerFeature('C2C_HiltiTR066Applications', [ 19 ]);

        if (!environment.c2cDemoFeatures) {
            if (environment.c2cThinOverlayAvailableCountries?.length > 0) {
                this.featureVisibilitySvc.registerFeature('C2C_ThinOverlayRegions', environment.c2cThinOverlayAvailableCountries);
            }
        }
        else {
            this.featureVisibilitySvc.registerFeature('C2C_TR069Seismic', true);
            this.featureVisibilitySvc.registerFeature('C2C_HnaFire', true);
            this.featureVisibilitySvc.registerFeature('C2C_MultiLoadPirEu', true);
            this.featureVisibilitySvc.registerFeature('C2C_UKTA', true);
            this.featureVisibilitySvc.registerFeature('C2C_EnableAciMeta', true);
            this.featureVisibilitySvc.registerFeature('C2C_WoodModuleESPT', true);
            this.featureVisibilitySvc.registerFeature('BulkReport', true);
            this.featureVisibilitySvc.registerFeature('C2C_WoodModuleE2', true);
            this.featureVisibilitySvc.registerFeature('C2C_DenmarkNA', true);
            this.featureVisibilitySvc.registerFeature('C2C_ThinOverlayRegions', [ -1 ]);
            this.featureVisibilitySvc.registerFeature('C2C_ThinOverlayNewRegions', [ -1 ]);
        }
    }
}
