import { Subscription } from 'rxjs';
import Sortable, { Options } from 'sortablejs';

import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit,
    ViewChild, ViewEncapsulation
} from '@angular/core';
import { PostRebar2d } from '@profis-engineering/c2c-gl-model/components/2d/post-rebar-2d';
import {
    ZoneName as ZoneNameGlModel
} from '@profis-engineering/c2c-gl-model/components/base-component';
import {
    IBaseMaterialsC2CModel
} from '@profis-engineering/c2c-gl-model/components/base-materials-c2c';
import { IZonesC2CModel } from '@profis-engineering/c2c-gl-model/components/zones-c2c';
import {
    calculateZoneColorFactors, getZoneName, getZoneNameByName
} from '@profis-engineering/c2c-gl-model/components/zones-helper-c2c';
import { IModelC2C, IScreenShotSettingsC2C } from '@profis-engineering/c2c-gl-model/gl-model';
import { Mode2dC2C } from '@profis-engineering/c2c-gl-model/mode-2d';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import { IModel, Mode2d, View2dModeType, ViewType } from '@profis-engineering/gl-model/gl-model';
import {
    CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    ToggleButtonGroupItem, ToggleButtonGroupProps
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Context3dKey } from '@profis-engineering/pe-ui-common/entities/context-3d';
import { DesignEvent, StateChange } from '@profis-engineering/pe-ui-common/entities/design';
import { IDesignSectionComponent } from '@profis-engineering/pe-ui-common/entities/design-section';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import {
    IMainMenuComponent, IMenu
} from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, NavigationTabWidth, UIPropertyBaseControl
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    INotificationsComponentInput, INotificationScopeCheck, NotificationLocation, NotificationType
} from '@profis-engineering/pe-ui-common/entities/notifications';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { Controls2dEditorBase } from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { IntroJs } from '@profis-engineering/pe-ui-common/services/tour.common';
import { ProjectAndDesignView } from '@profis-engineering/pe-ui-common/services/user.common';
import { GlModelC2CProps, IGlModelC2CComponent } from '../../../shared/components/gl-model';
import { InfoLink } from '../../../shared/entities/code-lists/info-link';
import { Command as CommandC2C, MainMenuCommandC2C } from '../../../shared/entities/command';
import { DesignC2C as Design, IDesignState } from '../../../shared/entities/design-c2c';
import { IDetailedDisplayDesign } from '../../../shared/entities/display-design';
import { AdvancedPointsTableType } from '../../../shared/entities/main-menu/main-menu-controls';
import { DesignType } from '../../../shared/entities/tracking-data';
import { ZoneAnalysisInput } from '../../../shared/entities/zone-analysis-input';
import { ProjectCodeList as ProjectCodeListC2C } from '../../../shared/enums/project-code-list';
import {
    LongitudinalReinforcementPointC2C, NumericalParameterC2C, PostInstalledReinforcementPointC2C,
    ScopeCheckResultItemEntityC2C, TranslationFormatC2C, TranslationParameterC2C,
    UIProperty as UIPropertyC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, CalculationMode, ConnectionType, DesignMethod, DesignMethodGroup,
    DesignStandard as DesignStandardC2C, LoadType as LoadTypeC2C, OverlayPosition,
    TranslationParameterType as TranslationParameterTypeC2C, ZoneName as ZoneNameC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import {
    ScopeCheckButtonParameterC2C, UIPropertyValueC2C
} from '../../../shared/generated-modules/Hilti.PE.ScopeChecks.Common.Entities';
import {
    DetailedScopeCheckInfoPopup, UIProperty as UISCPropertyC2C
} from '../../../shared/generated-modules/Hilti.PE.ScopeChecks.Common.Enums';
import {
    getModifiedDesignMethodKeyByDesign, isHnaAnchoringBarYieldMethod
} from '../../../shared/helpers/design-standard-methods-helper';
import { fromC2cUnitGroup } from '../../../shared/helpers/unit-helper';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import {
    Controls2dEditor as Controls2dEditorC2C, IMenu2dContext
} from '../../../shared/services/menu-2d.service.base';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { IExportReportSupportMethodsC2C } from '../../entities/export-report';
import { IDesignInfo } from '../../entities/module-initial-data';
import { ApprovalHelper } from '../../helpers/approval-helper';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { ChangesService } from '../../services/changes.service';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentServiceC2C } from '../../services/document.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { Menu2dService } from '../../services/menu-2d.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { RegionOrderService } from '../../services/region-order.service';
import { RoutingService } from '../../services/routing.service';
import { TourService } from '../../services/tour.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites } from '../../sprites';
import { AfterOpenInstruction } from '../add-edit-design/add-edit-design.component';
import { ViewType as UtilizationType } from '../layer-utilizations/layer-utilizations.component';

type MainMenuCommands = MainMenuCommandC2C;

enum DisplayOption {
    Zones,
    ZonesDimensions,
    ConcreteDimensionsC2C,
    LoadsC2C,
    BaseMaterialTransparent,
    ExistingReinforcement,
    NewConcreteStructure,
    ExistingConcreteStructure,
    ConnectorSpacingDimensions,
    ConnectorEdgeDistanceDimensions,
    PostRebarsNumber,
    PostRebarInstallationLengthsAndCovers,
    PostRebarDimensionsC2C,
    ContinuousXRepresentation
}

export enum DisplayOptionEditor {
    Editor3D,
    Editor2D,
    Both
}

const enum FloatingInfo {
    InfoSection = 1,
    ZoneAnalysis = 2,
    Sustainability = 3
}

const enum MenuTabs2DPir {
    Coordinates2D = '2d-coordinates',
    Pir = 'pir-tab'
}

const setTimeoutPromise = (handler: () => void, timeout?: number) => new Promise<void>((resolve, reject) => setTimeout(() => {
    try {
        handler();
        resolve();
    }
    catch (error) {
        reject(error);
    }
}, timeout));

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public glDebug!: boolean;

    // TODO BUDQBP-31813: This function should be moved to corresponding module to be used directly in module, and not passed here. Then cleanup main-component in pe-ui.
    @Input()
    public publish!: () => Promise<void>;

    // TODO BUDQBP-31813: This function should be moved to corresponding module to be used directly in module, and not passed here. Then cleanup main-component in pe-ui.
    @Input()
    public processDesignClose!: () => Promise<void>;

    // TODO BUDQBP-31813: This function should be moved to corresponding module to be used directly in module, and not passed here. Then cleanup main-component in pe-ui.
    @Input()
    public processDesignBrowserUnload!: () => Promise<void>;

    @ViewChild('mainContentCenterRightRef')
    public mainContentCenterRightRef!: ElementRef<HTMLElement>;

    @ViewChild('mainContentCenterRightTopRef')
    public mainContentCenterRightTopRef!: ElementRef<HTMLElement>;

    public CollapsingControls = CollapsingControls;
    public ViewType: Record<keyof typeof ViewType, ViewType> = {
        View2d: ViewType.View2d,
        View3d: ViewType.View3d
    };
    public FloatingInfo: Record<keyof typeof FloatingInfo, FloatingInfo> = {
        InfoSection: FloatingInfo.InfoSection,
        ZoneAnalysis: FloatingInfo.ZoneAnalysis,
        Sustainability: FloatingInfo.Sustainability
    };

    public design!: Design;

    public displayOptionsCheckbox!: Pick<CheckboxButtonProps<DisplayOption>, 'selectedValues' | 'items'>;
    public mode2dToggleButtonGroup!: Pick<ToggleButtonGroupProps<Mode2dC2C>, 'items'>;
    public view2dModeToggleButtonGroup!: Pick<ToggleButtonGroupProps<View2dModeType>, 'items'>;

    public modelViewZoom = 0;
    public rightSideLoaded = false;
    public hideLeftMenu = false;
    public hideRightMenu = false;
    public userLogout = false;

    @ViewChild('designSectionRef')
    public designSectionComponentRef!: ElementRef<IDesignSectionComponent>;

    @ViewChild('glModelRef')
    public glModelComponentElementRef!: ElementRef<IGlModelC2CComponent>;

    @ViewChild('mainMenuRef')
    public mainMenuComponentElementRef!: ElementRef<IMainMenuComponent>;

    public glModel!: Pick<GlModelC2CProps,
        'continuousRender' |
        'model' |
        'onFontsLoaded' |
        'onZoom' |
        'onSelectTab' |
        'onPositionsChanged' |
        'onDraggingSelectionChanged' |
        'onRebarClicked'
    >;

    public PropertyMetaDataC2C = PropertyMetaDataC2C;
    public Context3dKeyMain = Context3dKey.Main;

    public sortableMenu3DRightOptions!: Options;

    public exportReportSupportMethods!: IExportReportSupportMethodsC2C;

    public floatingInfo?: FloatingInfo;

    public zoneAnalysisInput?: ZoneAnalysisInput;

    public view: ViewType = ViewType.View3d;

    public notificationComponentInputs!: INotificationsComponentInput;

    private menu3dSelectedTab!: string;

    private zoneAnalysisInitialized = false;
    private localizationChangeSubscription!: Subscription;

    private _mode2d = Mode2d.Pointer;

    private _view2dMode = View2dModeType.Top;

    private openedVirtualTour?: IntroJs;

    constructor(
        private readonly routingService: RoutingService,
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public licenseService: LicenseService,
        public offlineService: OfflineService,
        public modalService: ModalService,
        private readonly userService: UserService,
        private readonly regionOrderService: RegionOrderService,
        private readonly unitService: UnitService,
        private readonly codeListServiceC2C: CodeListServiceC2C,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly numberService: NumberService,
        private readonly designTemplateService: DesignTemplateService,
        private readonly documentService: DocumentServiceC2C,
        private readonly tourService: TourService,
        private readonly menuService: MenuService,
        private readonly menu2dService: Menu2dService,
        private readonly favoritesService: FavoritesService,
        private readonly changesService: ChangesService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly calculationServiceC2C: CalculationServiceC2C,
        private readonly featuresVisibilityService: FeaturesVisibilityService,
        private readonly translationFormatService: TranslationFormatService,
        private readonly applicationProviderService: ApplicationProviderService,
        private readonly ngZone: NgZone,
        private readonly elementRef: ElementRef<HTMLElement>
    ) {
    }

    public get isNewHomePage() {
        return this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }

    public get zoomUnit() {
        return Unit.percent;
    }

    public get glModelComponent() {
        return this.glModelComponentElementRef?.nativeElement;
    }

    public get selectedMenu(): IMenu {
        return this.mainMenuComponent?.getSelectedMenu() ?? {};
    }

    public get mainMenuComponent() {
        return this.mainMenuComponentElementRef?.nativeElement;
    }

    public get displayLDFlags() {
        return this.featuresVisibilityService.isFeatureEnabled('LD_AutomatedPage');
    }

    public get mode2d() {
        return this._mode2d;
    }

    public set mode2d(value) {
        if (this._mode2d !== value) {
            this._mode2d = value;

            this.mode2d == Mode2d.Pan ? this.setCursor('move') : this.setCursor('');

            this.glModelComponent.update({
                editor2d: { mode: this.mode2d }
            });
        }
    }
    public get view2dMode() {
        return this._view2dMode;
    }
    public set view2dMode(value) {
        if (this._view2dMode !== value) {
            this._view2dMode = value;

            this.on2dViewModeChange(value);
        }
    }

    public get regionLanguage() {
        return this.userSettingsService.getRegionLanguage();
    }

    public get language() {
        return this.userSettingsService.getLanguage();
    }

    public get showDesignMethods() {
        return this.selectedMethodsLength > 0;
    }

    public get hasExtendedWidth() {
        const selectedTab = this.selectedMenu?.tabs?.[this.selectedMenu?.selectedTab];
        const canExtend = selectedTab?.width == NavigationTabWidth.Extended;
        return canExtend && !this.hideLeftMenu;
    }

    public get isLoading() {
        return this.design.loading;
    }

    public get isPirEu() {
        return (this.design.connectionType == ConnectionType.Splices || this.design.connectionType == ConnectionType.StructuralJoints) &&
            (this.design.designStandardC2C?.id == DesignStandardC2C.ETAG || this.design.isAusEN);
    }

    public get isAusEN() {
        return this.design.designStandardC2C?.id == DesignStandardC2C.ASBased && this.design.designMethodGroup?.id == DesignMethodGroup.EN199211;
    }

    public get isPirEuOrAus() {
        return (this.design.connectionType == ConnectionType.Splices || this.design.connectionType == ConnectionType.StructuralJoints) &&
            (this.design.designStandardC2C?.id == DesignStandardC2C.ETAG || this.design.designStandardC2C?.id == DesignStandardC2C.ASBased);
    }

    public get isSplicesEU() {
        return this.design.connectionType == ConnectionType.Splices && this.design.designStandardC2C?.id == DesignStandardC2C.ETAG;
    }

    public get isSplicesEUorAUS() {
        return this.design.connectionType == ConnectionType.Splices && (this.design.designStandardC2C?.id == DesignStandardC2C.ETAG || this.design.designStandardC2C?.id == DesignStandardC2C.ASBased);
    }

    public get isPirHna() {
        return (this.design.connectionType == ConnectionType.Splices || this.design.connectionType == ConnectionType.StructuralJoints) &&
            (this.design.designStandardC2C?.id == DesignStandardC2C.ACI || this.design.designStandardC2C?.id == DesignStandardC2C.CSA);
    }

    public get showZoneAnalysis(): boolean {
        return this.zoneAnalysisInitialized && this.design.connectionType == ConnectionType.ConcreteOverlay &&
            this.design.applicationType != ApplicationType.GenericReinforcement;
    }

    public get title() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        let title = `${this.design.isTemplate ? this.design.templateName : this.design.designName}`;
        title += ` (${this.design.projectName})`;
        title += `, ${this.design.region.getTranslatedNameText(codeListDeps)}`;
        title += `, ${this.design.designStandardC2C?.getTranslatedNameText(codeListDeps)}`;
        if (this.isPirEuOrAus) {
            title += `, ${this.translate(this.hasAlertScopeChecksC2C ? 'Agito.Hilti.Profis3.DesignReportData.DesignMethod.None' : this.design.designData.projectDesignC2C.options.selectedDesignMethodTranslationKey)}`;
        }
        else if (this.design.designMethodGroup != undefined) {
            if (this.hasAlertScopeChecksC2C) {
                title += `, ${this.translate('Agito.Hilti.Profis3.DesignReportData.DesignMethod.None')}`;
            }
            else {
                const designMethod = getModifiedDesignMethodKeyByDesign(this.design, this.featuresVisibilityService);
                const modifiedString = isHnaAnchoringBarYieldMethod(this.design)
                ? this.translate('Agito.Hilti.C2C.CodeList.DesignMethodEntity.General.ModifiedFrom')
                : '';
                const designMethodString = designMethod.getTranslatedNameText(codeListDeps);
                title += `, ${modifiedString} ${designMethodString}`;

                if (this.isPirHna && this.design.projectDesign.loads.shearDesignMethod == DesignMethod.HiltiMethodRebarDesign) {
                    title += ` & ${this.translate('Agito.Hilti.C2C.CodeList.DesignMethodGroupEntity.HiltiMethod')}`;
                }
            }
        }
        if (!this.hasAlertScopeChecksC2C) {
            if (this.design.designData.reportDataC2C.approvalDetails != null && this.design.designData.reportDataC2C.approvalDetails.number != '') {
                title += `, ${this.design.designData.reportDataC2C.approvalDetails.number}`;
            }
            else {
                title += `, ${this.translate('Agito.Hilti.C2C.DesignReportData.HiltiTechnicalData')}`;
            }
        }

        return title;
    }

    public get displayOptionsTooltip() {
        return this.translate(this.view == ViewType.View3d ? 'Agito.Hilti.Profis3.Main.ShowHideElements' : 'Agito.Hilti.Profis3.Main.ShowHideElements2D');
    }

    public get undoRedoHidden() {
        return this.featuresVisibilityInfoService.isHidden(Feature.Design_UndoRedo, this.design.region.id);
    }

    public get undoRedoDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Design_UndoRedo, this.design.region.id);
    }

    public get undoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Undo');
    }

    public get redoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Redo');
    }

    public get isBeamBottomOverlay() {
        return this.design.applicationType == ApplicationType.BeamReinforcement &&
            this.design.designData.projectDesignC2C?.overlayInstallation.position == OverlayPosition.BottomOverlay;
    }

    public get areRebarsEditable() {
        return this.design.isPirEuOrAus && this.design.designData.projectDesignC2C.product.calculationMode == CalculationMode.Verification &&
            (this.design.designData.projectDesignC2C.application.angle == 0 && this.view2dMode == View2dModeType.Front ||
                this.design.designData.projectDesignC2C.application.angle != 0 && this.view2dMode == View2dModeType.Top);
    }

    private get hasAlertScopeChecksC2C() {
        const alertScopeChecks = this.design.designData.reportDataC2C.scopeChecks.filter(sc => sc.indicatesCalculationError) ?? [];
        return alertScopeChecks.length > 0;
    }

    private get isZonesVisible() {
        return this.design.connectionType == ConnectionType.ConcreteOverlay
            && this.design.applicationType != ApplicationType.GenericReinforcement;
    }

    private get isRebarApplication() {
        return this.design.connectionType == ConnectionType.Splices || this.design.connectionType == ConnectionType.StructuralJoints;
    }

    private get isOverlayApplication() {
        return this.design.connectionType == ConnectionType.ConcreteOverlay;
    }

    private get selectedMethodsLength() {
        if (this.isPirEuOrAus)
            return (this.design.projectDesign.options.designMethodGroups ?? []).length;

        return this.design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods)
            ? this.design.projectDesign.loads.designMethods.length
            : 0;
    }

    public scopeCheckButtonClickC2C(uiPropertyValues: UIPropertyValueC2C[]) {
        for (const uiProperty of uiPropertyValues) {
            if (uiProperty.property === UISCPropertyC2C.Loads_C2C_DefinitionOfMinMaxReinforcement) {
                this.design.usageCounterC2C.DefinitionOfMinMaxReinforcement++;
                this.openDesignSettings([AfterOpenInstruction.ScrollToRebarCalculationSection]);
            }
            else {
                this.design.addModelChangeNoCalculation(uiProperty.property, true, uiProperty.value);
            }
        }

        this.calculationServiceC2C.calculateAsync(this.design);
    }

    public notificationButtonsTooltip(translationKey: string) {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_ScopeCheckButtons) ||
            this.localizationService.getString(translationKey);
    }

    private getConvertedUnitValue(numberValue: number, unit: Unit, unitGroup: UnitGroup) {
        if (unitGroup) {
            const internalUnit = this.unitService.getInternalUnit(unitGroup);
            return this.unitService.convertUnitValueArgsToUnit(numberValue, internalUnit, unit);
        }

        return numberValue;
    }

    private getNumericScopeCheckHtmlBase(numberValue: number, unitGroup: UnitGroup, additionalPrecision: number) {
        let unit = Unit.None;
        if (unitGroup) {
            unit = this.unitService.getDefaultUnit(unitGroup);
        }

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const precision = this.unitService.getPrecision(unit) + (additionalPrecision || 0);

        numberValue = this.getConvertedUnitValue(numberValue, unit, unitGroup);
        let displayedUnitValue = this.unitService.formatNumber(numberValue, precision);
        let exactUnitValue = this.unitService.formatNumber(numberValue, maxPrecision);

        if (displayedUnitValue.length >= exactUnitValue.length) {
            return null;
        }

        // Displayed with less decimals than internally used!
        displayedUnitValue = this.unitService.appendPrecisionLossSymbolToValueString(numberValue, displayedUnitValue);

        if (unitGroup) {
            displayedUnitValue = this.unitService.appendUnitToValueString(numberValue, displayedUnitValue, unit);
            exactUnitValue = this.unitService.appendUnitToValueString(numberValue, exactUnitValue, unit);
        }

        return `<span class="additional-info" title="${exactUnitValue}">${displayedUnitValue}</span>`;
    }

    private getNumericScopeCheckHtmlC2C(parameter: TranslationParameterC2C, roundValue: boolean) {
        if (
            parameter.parameterType !== TranslationParameterTypeC2C.Numerical ||
            !roundValue
        ) {
            // Handle only Numerical parameters with rounding
            return null;
        }

        const numericalParameter = parameter as NumericalParameterC2C;
        if (numericalParameter.value == null) {
            return null;
        }

        return this.getNumericScopeCheckHtmlBase(numericalParameter.value, fromC2cUnitGroup(numericalParameter.unitGroup), numericalParameter.additionalPrecision as number);
    }

    public getScopeCheckHtmlC2C(scopeCheckMessage: TranslationFormatC2C) {
        const transformedParams = this.translationFormatService.transformTranslationParameters(
            scopeCheckMessage.translationParameters,
            true,
            undefined,
            this.getNumericScopeCheckHtmlC2C.bind(this)
        );

        const html = this.translationFormatService.getLocalizedStringWithTranslationFormat(
            scopeCheckMessage,
            true,
            transformedParams
        );

        return html?.replace(/τ/g, '<span class="tauFontSmall">τ</span>');
    }

    public getButtonTitleC2C(actionButton: ScopeCheckButtonParameterC2C) {
        let translationText = this.localizationService.getString(actionButton.buttonTitle);

        if (actionButton.titleTranslationParameters == null) {
            return translationText;
        }

        for (const key in actionButton.titleTranslationParameters) {
            const titleValue = actionButton.titleTranslationParameters[key];

            if (titleValue !== null && typeof titleValue === 'object' && !Array.isArray(titleValue)) {
                const transformedParams = this.translationFormatService.transformTranslationParameters(
                    [titleValue],
                    true,
                    undefined,
                    (parameter, roundValue) => this.getNumericScopeCheckHtmlC2C(parameter, roundValue)
                );
                translationText = translationText.replace(`{${key}}`, transformedParams[key]);
            } else {
                translationText = translationText.replace(`{${key}}`, titleValue);
            }
        }

        return translationText;
    }

    public get notificationScopeChecksC2C() {
        return this.design?.designData.reportDataC2C?.scopeChecks?.map(sc => ({
            type: sc.indicatesCalculationError ? NotificationType.alert : NotificationType.info,
            message: this.getScopeCheckHtmlC2C(sc.message as TranslationFormatC2C),
            actionButtons: sc.actionButtons?.map(ab => ({
                condition: () => { return true; },
                disabled: () => { return false; },
                click: () => {
                    return this.ngZone.run(() => this.scopeCheckButtonClickC2C(ab.uiPropertyValueList));
                },
                buttonTitle: this.getButtonTitleC2C(ab),
                tooltip: this.notificationButtonsTooltip(ab.buttonTooltip),
                disableTooltip: () => { return !this.localizationService.hasTranslation(ab.buttonTooltip); }
            })),
            hasSupportButton: () => { return false; },
            supportButtonClick: () => { return; },
            hasInfoButton: () => { return this.hasInfoPopupAvailableC2C(sc); },
            infoButtonClick: () => { return this.openInfoPopupC2C(sc); }
        } as INotificationScopeCheck));
    }

    public hasInfoPopupAvailableC2C(scopeCheck: ScopeCheckResultItemEntityC2C): boolean {
        return scopeCheck.infoPopup != DetailedScopeCheckInfoPopup.None;
    }

    public openInfoPopupC2C(scopeCheck: ScopeCheckResultItemEntityC2C): void {
        if (!this.hasInfoPopupAvailableC2C(scopeCheck))
            return;

        this.modalService.openInfoScopeCheckDialogC2C(scopeCheck.infoPopup);
    }

    public get hnaTechManualLink(): string {
        const infoLinks = this.codeListServiceC2C.projectCodeListsC2C[ProjectCodeListC2C.InfoLinkC2C] as InfoLink[];
        const commandInfoLink = infoLinks.find(x => x.commandId == CommandC2C.OpenRecommendFireFastener);

        return commandInfoLink?.regionLinks?.find(y => y.regionId == this.design.region.id)?.link ?? '';
    }

    private get hasScopeChecksC2C() {
        return this.scopeChecksC2C.length > 0;
    }

    private get scopeChecksC2C() {
        return this.design?.designData.reportDataC2C.scopeChecks ?? [];
    }

    private get isLongCalculationVisibleC2C() {
        // XXX: Ico: is this even needed?
        return this.design?.isC2C &&
            this.design?.designData.reportDataC2C != null &&
            !this.design?.designData.reportDataC2C.scopeCheckResultItems?.some(x => x.indicatesCalculationError) &&
            this.design?.designData.reportDataC2C.isLongRunningCalculation &&
            this.design?.designData.reportDataC2C.zonesUtilizations == null &&
            this.design?.designData.reportDataC2C.utilizations == null;
    }

    private get isHnaFire() {
        return this.design?.isC2CHNA && this.design?.loadTypeC2C == LoadTypeC2C.Fire;
    }

    public openHnaTechManualLink() {
        window.open(this.hnaTechManualLink, '_blank');
    }

    public runAdvancedCalculationC2C() {
        this.calculationServiceC2C.calculateAsync(this.design, undefined, { calculateLongRunning: true, forceCalculation: true });
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-left-medium',
            'sprite-arrow-right-medium',
            'sprite-undo',
            'sprite-redo',
            'sprite-search',
            'sprite-center',
            'sprite-view',
            'sprite-pointer',
            'sprite-move',
            'sprite-plus-red',
            'sprite-trash',
            'sprite-top-view',
            'sprite-front-view',
            'sprite-side-view',
            'sprite-arrow-left-white'
        );

        this.design = this.userService.design;

        this.menuService.initialize();
        this.menu2dService.initialize();
        this.tourService.initialize();

        this.glModelComponentUpdate = this.glModelComponentUpdate.bind(this);
        this.resize3d = this.resize3d.bind(this);
        this.createDesignScreenshot = this.createDesignScreenshot.bind(this);
        this.tabSelected = this.tabSelected.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onCloseEventTrack = this.onCloseEventTrack.bind(this);
        this.beforeLogout = this.beforeLogout.bind(this);
        this.openDesignSettings = this.openDesignSettings.bind(this);
        this.openSaveAsTemplate = this.openSaveAsTemplate.bind(this);
        this.startTour = this.startTour.bind(this);
        this.openGeneralNotes = this.openGeneralNotes.bind(this);

        this.displayOptionsCheckbox = {
            items: this.createDisplayOptionsCheckboxItems(),
            selectedValues: this.getDisplayOptionsCheckboxSelectedValuesFromUserSettings()
        };

        this.mode2dToggleButtonGroup = {
            items: this.createMode2dToggleButtonGroupItems()
        };
        this.view2dModeToggleButtonGroup = {
            items: this.createView2dModeToggleButtonGroupItems()
        };

        this.sortableMenu3DRightOptions = {
            handle: '.drag-handle-static',
            store: {
                get: () => {
                    return [];
                },
                set: (sortable) => {
                    this.regionOrderService.update(sortable.toArray(), MenuType.Menu3DRight);
                }
            },
            onSort: () => undefined
        };

        this.TrackDataOnTabClose();

        // show the page and then load controls after GLModel asynchronously
        setTimeoutPromise(() => this.initGLModel())
            .then(() => setTimeoutPromise(() => this.initMenu3d()))
            .then(() => setTimeoutPromise(() => this.initZoneAnalysisDropdown()))
            .then(() => setTimeoutPromise(() => this.initRightSideC2C()))
            .then(() => setTimeoutPromise(() => this.startDesignTour()))
            .catch(err => console.error(err));

        this.zoneAnalysisInput = {
            getZoneNameByName: zone => getZoneNameByName(zone) as unknown as ZoneNameC2C,
            calculateZoneColorFactors: (n, z) => calculateZoneColorFactors(n, z as unknown as ZoneNameGlModel),
            getZoneName: (n) => getZoneName(n),
            createScreenshot: () => Promise.resolve(undefined as unknown as string)
        };

        // attach events to document
        document.addEventListener('keydown', this.onKeyDown, false);

        this.localizationChangeSubscription = this.localizationService.localizationChange.subscribe(() => {
            const selected = this.selectedMenu.selectedTab;

            this.initMenu3d();

            // init 3d menu always to change translations, etc.
            if (this.view == ViewType.View2d) {
                // save reinitialized 3d menu

                this.initMenu2d();
            }

            this.selectTabById(selected);
        });

        this.setNotificationComponentInputs();

        this.design.onStateChanged(() => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                this.onDesignStateChangedUpdateValues();
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });

        this.displayDesignConsiderationPopup();
    }

    public ngAfterViewInit(): void {
        if (this.userService.renameFile) {
            this.openDesignSettings();
            this.userService.renameFile = false;
        }

        // CR: what exactly is this?
        if (this.userService.openDesign) {
            setTimeout(() => {
                this.documentService.openDesignExclusiveC2C(this.userService.design);
                this.userService.openDesign = false;
            }, 1000);
        }

        this.design.setSavedStateIdx();

        if (this.design?.designData?.reportDataC2C?.utilizations?.some(u => u.name == UtilizationType.TotalProductionEmissions))
        {
            this.floatingInfoChange(FloatingInfo.Sustainability, false);
        }
    }

    public ngOnDestroy(): void {
        this.localizationChangeSubscription?.unsubscribe();

        if (this.design != undefined) {
            this.design.off(DesignEvent.stateChanged, this.onStateChanged);
            this.design.off(DesignEvent.beforeCalculate, this.onBeforeCalculate);
            this.design.off(DesignEvent.calculate, this.onCalculate);
            this.publish();

            // angular bug: template render is still called multiple times after ngOnDestroy for some reason
            // this causes errors since this.design is already null
            setTimeout(() => {
                this.design = undefined as any;
            }, 100);
        }

        // remove document events
        document.removeEventListener('keydown', this.onKeyDown, false);
        window.removeEventListener('beforeunload', this.onCloseEventTrack, false);

        // Fix for the issue that happens when you close the design before the introduction loads
        if (this.openedVirtualTour != null) {
            setTimeout(() => {
                document.querySelectorAll('.introjs-helperLayer, .introjs-tooltipReferenceLayer, .introjs-tooltip, .introjs-overlay, .introjs-disableInteraction')
                    .forEach(element => element.remove());
            });
        }
    }

    public glModelComponentUpdate(model: IModelC2C, replace?: boolean) {
        return this.glModelComponent.update(model, replace);
    }

    public TrackDataOnTabClose() {
        window.addEventListener('beforeunload', this.onCloseEventTrack, false);
    }

    public displayOptionsVisible(displayOptionEditor: DisplayOptionEditor) {
        return displayOptionEditor == DisplayOptionEditor.Both || (this.view == ViewType.View2d && displayOptionEditor == DisplayOptionEditor.Editor2D) || (this.view == ViewType.View3d && displayOptionEditor == DisplayOptionEditor.Editor3D);
    }

    public menuOpened() {
        this.userService.design.usageCounterC2C.MenuOpened++;
    }

    public resize3d(resetHighlightedComponents?: boolean) {
        if (this.glModelComponent != null) {
            if (resetHighlightedComponents) {
                this.glModelComponent.resetHighlightedComponents();
            }

            this.glModelComponent.resizeNextFrame();
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public async beforeLogout() {
        if (!this.design.isTemplate) {
            this.userLogout = true; // we set this to true so we ignore beforeunload event
            await this.processDesignClose();
        }
    }

    public onPlusClick() {
        this.glModelComponent.cameraZoomIn();
    }

    public onMinusClick() {
        this.glModelComponent.cameraZoomOut();
    }

    public on2dViewModeChange(viewMode2d: View2dModeType) {
        // XXX: Ico: is this even needed?
        if (this.design.isC2C) {
            this.mode2d = Mode2d.Pointer;
        }

        this.mode2dToggleButtonGroup = {
            items: this.createMode2dToggleButtonGroupItems()
        };

        this.glModelComponent.update({
            view2dMode: viewMode2d
        });
    }

    public setCursor(cursor: 'move' | '') {
        this.mainContentCenterRightRef.nativeElement.style.cursor = cursor;
    }

    public openClose2d() {
        if (this.view == ViewType.View2d) {
            this.dismiss2dInternal();
        }
        else if (this.view == ViewType.View3d) {
            this.open2d();
        }
    }

    public openGeneralNotes() {
        // TODO: BUDQBP-23561

        const copyText = this.localizationService.getString('Agito.Hilti.C2C.GeneralNotes.CopyText');
        const text = this.localizationService.getString('Agito.Hilti.C2C.GeneralNotes.DisplayText');

        this.modalService.openGeneralNotes(text, copyText);
    }

    public getDesignInfoForDesignType() {
        this.applicationProviderService.designStandardId = this.design.designStandardC2C?.id;
        return this.applicationProviderService.getDesignInfo().find(x => x.designTypeId == this.design.designType?.id && x.isAvailable(this.design.regionId)
                                        && (this.design.connectionType == null || x.connectionType == null || x.connectionType?.includes(this.design.connectionType))) as IDesignInfo;
    }

    public openDesignSettings(afterOpenInstructions?: AfterOpenInstruction[]) {
        const designInfo = this.getDesignInfoForDesignType();
        this.modalService.openAddEditDesignFromModule({
            design: {
                id: this.design.id,
                name: this.design.designName,
                projectId: this.design.projectId,
                projectName: this.design.projectName,
                region: this.design.region,
                designType: this.design.designType?.id,
                displayDesignType: this.design.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
                design: this.design
            },
            addEditType: AddEditType.edit,
            afterOpenInstructions: afterOpenInstructions,
            selectedModuleDesignInfo: designInfo,
            onDesignEdited:(design, project) => {
                this.userService.changeDesign(project as Project, this.design);
                this.userService.mapDisplayDesignToDesign(design as IDetailedDisplayDesign);
                this.userService.projectAndDesignView = project == this.documentService.draftsProject
                    ? ProjectAndDesignView.drafts
                    : ProjectAndDesignView.projects;

                return this.ngZone.run(() => this.calculationServiceC2C.calculateAsync(this.design, undefined, { suppressLoadingFlag: true })
                    .then(() => undefined)
                );
            }
        });
    }

    public async openSaveAsTemplate() {
        this.modalService.openSaveAsTemplate({
            designTemplateDocument: this.getDesignTemplateDocumentC2C(),
            thumbnailId: this.design.id,
            onTemplateSaved: this.onTemplateSaved.bind(this)
        });
    }

    private getDesignTemplateDocumentC2C(): IDesignTemplateDocument {
        return {
            designTypeId: DesignType.Concrete2Concrete,
            designStandardId: this.design.designData.projectDesignC2C.options.designStandard,
            regionId: this.design.designData.projectDesignC2C.options.regionId,
            anchorName: undefined,
            approvalNumber: this.design.designData.reportDataC2C?.approvalDetails?.number ?? '',
            projectDesign: JSON.stringify(this.design.designData.projectDesignC2C)
        };
    }

    public isSpecificationTextEmpty() {
        return this.userService.design.designData.reportDataC2C.specificationText == null;
    }

    public startTour() {
        this.modalService.openVirtualTourPopup(this.selectTab.bind(this));
    }

    public startDesignTour() {
        if (this.userService?.design == null) {
            return;
        }

        // Get all tours that are available for design and not yet seen.
        const availableTours = this.tourService.getVirtualTours()
            .filter(x => x.order != null)
            .filter(x => SafeFunctionInvokerHelper.safeInvoke(x.isAvailable, false))
            .filter(x => !SafeFunctionInvokerHelper.safeInvoke(x.alreadySeen, false))
            .sort((a, b) => ((a.order ?? 0) - (b.order ?? 0)));

        // First tour shall start immediately, others should start after previous one is completed.
        const startNextTour = () => {
            const tour = availableTours.shift();
            if (tour == null) {
                return;
            }

            this.openedVirtualTour = tour.openTour(this.selectTab.bind(this));

            // oncomplete is triggered if Got It button is clicked on last step, onexit is triggered if dismiss button is clicked on any other step
            const exitFn = () => {
                this.openedVirtualTour = undefined;
                tour.markAsSeen();
                startNextTour();
            };
            this.openedVirtualTour.oncomplete(exitFn.bind(this));
            this.openedVirtualTour.onexit(exitFn.bind(this));
        };

        startNextTour();
    }

    public regionLinkOpened() {
        this.design.usageCounterC2C.OnlineTechnicalInformation++;
    }

    public hiltiDataPrivacyUrlOpened() {
        this.design.usageCounterC2C.OnlineTechnicalInformation++;
    }

    public openApplicationSettings() {
        this.modalService.openApplicationSettings();
    }

    public displayDesignConsiderationPopup() {
        if (this.design.designData.dialogs?.showDesignConsiderationPopup) {
            this.modalService.openDesignConsideration();
        }
    }

    public openUpgradePE() {
        if (this.userSettingsService.getProfis3Url) {
            // TODO: BUDQBP-23561

            const profis3Url = this.regionLanguage.getProfis3Url as string;
            this.offlineService.nativeExternalLinkOpen(profis3Url);
        }
    }

    public zoomPercentageChange(value: number) {
        // The value is changed inside the same cycle so change detection
        // needs to be run again before the new change
        this.modelViewZoom = value;
        this.changeDetector.detectChanges();

        this.glModelComponent.cameraZoom(value);
    }

    public resetCamera() {
        this.glModelComponent.resetCamera();
    }

    public toggleLeftMenu() {
        this.hideLeftMenu = !this.hideLeftMenu;

        this.resize3dAfterUI();
    }

    public toggleRightMenu() {
        this.hideRightMenu = !this.hideRightMenu;

        this.resize3dAfterUI();
    }

    public undo() {
        if (!this.canUndo()) {
            return;
        }

        this.design.usageCounterC2C.Undo++;
        this.calculationServiceC2C.undo(this.design);

        this.setNotificationComponentInputs();
    }

    public redo() {
        if (!this.canRedo()) {
            return;
        }

        this.design.usageCounterC2C.Redo++;
        this.calculationServiceC2C.redo(this.design);

        this.setNotificationComponentInputs();
    }

    public canUndo() {
        return this.design.canUndo && !this.undoRedoDisabled && !this.design.isReadOnlyDesignMode;
    }

    public canRedo() {
        return this.design.canRedo && !this.undoRedoDisabled && !this.design.isReadOnlyDesignMode;
    }


    public zoomToFit() {
        const extraInfo = {
            imgHeight: 0,
            imgWidth: 0,
            zoomed: false,
            preview: true,
            loadsVisibilityInfo: {
                designType: this.design.designType?.id,
                preview: true,
                modifyLoads: false,
                showInwardsWindLoad: false,
                showLinearInwardsLoad: false,
                showLinearOutwardsLoad: false,
                showOutwardsWindLoad: false,
                showVerticalLoad: false
            }
        };

        return this.glModelComponent.zoomToFit(extraInfo);
    }

    public loadsVisible() {
        return this.view == ViewType.View3d && !this.design.properties.get(PropertyMetaDataC2C.Loads_C2C_LoadCombinations.id).hidden;
    }

    public displayOptionsCheckboxItemToggle(displayOption: DisplayOption) {
        const checked = this.displayOptionsCheckbox.selectedValues?.has(displayOption) as boolean;

        switch (displayOption) {
            case DisplayOption.PostRebarsNumber: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        showPostRebarsNumber: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.postRebarsNumber.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.Zones: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        zones: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.zonesVisible.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ZonesDimensions: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        zonesDimensions: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.zonesDimensionsVisible.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ConcreteDimensionsC2C: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        baseMaterialsDimensions: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensionsC2C.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.LoadsC2C: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        loads: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.loadsC2C.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.BaseMaterialTransparent: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        baseMaterialTransparent: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterialC2C.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ExistingReinforcement: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        existingReinforcement: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.existingReinforcement.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.NewConcreteStructure: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        newConcreteStructure: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.newConcreteStructure.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ExistingConcreteStructure: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        existingConcreteStructure: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.existingConcreteStructure.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ConnectorSpacingDimensions: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        connectorSpacingDimensions: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.connectorSpacingDimensions.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ConnectorEdgeDistanceDimensions: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        connectorEdgeDistanceDimensions: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.connectorEdgeDistanceDimensions.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.PostRebarInstallationLengthsAndCovers: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        showPostRebarInstallationLengthsAndCovers: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.postRebarInstallationLengthsAndCovers.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.PostRebarDimensionsC2C: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        postRebarDimensions: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.postRebarDimensions.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ContinuousXRepresentation: {
                this.glModelComponent.update({
                    visibilityPropertiesC2C: {
                        showContinuousXInfinityObjects: checked
                    }
                });
                break;
            }
            default:
                throw new Error('unknown DisplayOption');
        }

        setTimeout(() => {
            this.createDesignScreenshot();
        });
    }

    public sortMenu3DRight(sortable: Sortable) {
        sortable.sort(this.favoritesService.menu3DRightOrder.map(String));
    }

    public floatingInfoChange(floatingInfo: FloatingInfo, collapsed: boolean) {
        if (collapsed && this.floatingInfo == floatingInfo) {
            // everything is collapsed
            this.floatingInfo = undefined;
        }
        else if (!collapsed) {
            // open this specific floating info
            this.floatingInfo = floatingInfo;
        }
    }

    private onCloseEventTrack() {
        if (!this.userLogout) {
            this.processDesignBrowserUnload();
        }
    }

    private createDisplayOptionsCheckboxItems(): CheckboxButtonItem<DisplayOption>[] {
        const items: CheckboxButtonItem<DisplayOption>[] = [];

        if (this.isZonesVisible && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Zones],
                value: DisplayOption.Zones,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Zones'),
                disabled: false
            });
        }

        if (this.isZonesVisible && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ZonesDimensions],
                value: DisplayOption.ZonesDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ZonesDimensions'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ConcreteDimensionsC2C],
                value: DisplayOption.ConcreteDimensionsC2C,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.ConcreteDimensions'),
                disabled: false
            });
        }

        if (this.isRebarApplication && this.displayOptionsVisible(DisplayOptionEditor.Editor3D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.LoadsC2C],
                value: DisplayOption.LoadsC2C,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.Loads'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.BaseMaterialTransparent],
                value: DisplayOption.BaseMaterialTransparent,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.TransparentConcrete'),
                disabled: false
            });
        }

        if (this.isSplicesEUorAUS && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ExistingReinforcement],
                value: DisplayOption.ExistingReinforcement,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.ExistingReinforcement'),
                disabled: false
            });
        }

        if (this.isPirHna && this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            items.push({
                id: `DisplayOption-${DisplayOption[DisplayOption.PostRebarDimensionsC2C]}`,
                value: DisplayOption.PostRebarDimensionsC2C,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.PostRebarDimensionsC2C'),
                disabled: false
            });
        }

        if (this.isRebarApplication && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.PostRebarsNumber],
                value: DisplayOption.PostRebarsNumber,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.PostRebarsNumber'),
                disabled: false
            });
        }

        if (this.isOverlayApplication && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.NewConcreteStructure],
                value: DisplayOption.NewConcreteStructure,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.NewConcreteStructure'),
                disabled: false
            });
        }

        if (this.isOverlayApplication && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ExistingConcreteStructure],
                value: DisplayOption.ExistingConcreteStructure,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.ExistingConcreteStructure'),
                disabled: false
            });
        }

        if (this.isOverlayApplication && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ConnectorSpacingDimensions],
                value: DisplayOption.ConnectorSpacingDimensions,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.ConnectorSpacingDimensions'),
                disabled: false
            });
        }

        if (this.isOverlayApplication && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ConnectorEdgeDistanceDimensions],
                value: DisplayOption.ConnectorEdgeDistanceDimensions,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.ConnectorEdgeDistanceDimensions'),
                disabled: false
            });
        }

        if (this.isPirEuOrAus && this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.PostRebarInstallationLengthsAndCovers],
                value: DisplayOption.PostRebarInstallationLengthsAndCovers,
                text: this.localizationService.getString('Agito.Hilti.C2C.Main.DisplayOptions.PostRebarInstallationLengthsAndCovers'),
                disabled: false
            });
        }

        if (this.design.isPirEuOrAus && this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.design.properties.get(UIPropertyC2C.Application_C2C_Connection_IsXDirectionContinuous).hidden) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ContinuousXRepresentation],
                value: !(this.design.model[UIPropertyC2C.Application_C2C_Connection_IsXDirectionContinuous] as boolean) ? undefined as any : DisplayOption.ContinuousXRepresentation,
                text: this.localizationService.getString('Agito.Hilti.C2C.ConnectionApplication.XDirectionContinousRepresentation'),
                disabled: !(this.design.model[UIPropertyC2C.Application_C2C_Connection_IsXDirectionContinuous] as boolean),
            });
        }

        return items;
    }

    private createMode2dToggleButtonGroupItems(): ToggleButtonGroupItem<Mode2dC2C>[] {
        const items: ToggleButtonGroupItem<Mode2dC2C>[] = [];

        items.push({
            id: 'main-2D-editor-pointer-button',
            value: Mode2d.Pointer,
            tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.Select'),
            image: getSpriteAsIconStyle('sprite-pointer')
        });

        items.push({
            id: 'main-2D-editor-pan-button',
            value: Mode2d.Pan,
            tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.Pan2d'),
            image: getSpriteAsIconStyle('sprite-move')
        });

        if (this.areRebarsEditable) {
            items.push({
                id: 'main-2D-editor-rebar-point-button',
                value: Mode2dC2C.AddRebarPoint,
                tooltip: this.translate('Agito.Hilti.C2C.Main.Editor2d.AddRebar'),
                image: getSpriteAsIconStyle('sprite-plus-red')
            });
        }

        if (this.areRebarsEditable) {
            items.push({
                id: 'main-2D-editor-remove-button',
                value: Mode2d.Delete,
                tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.Remove'),
                image: getSpriteAsIconStyle('sprite-trash')
            });
        }

        return items;
    }

    private createView2dModeToggleButtonGroupItems(): ToggleButtonGroupItem<View2dModeType>[] {
        const items: ToggleButtonGroupItem<View2dModeType>[] = [];

        if (this.isBeamBottomOverlay) {
            items.push({
                id: 'main-2D-editor-bottom-view-button',
                value: View2dModeType.Top,
                tooltip: this.translate('Agito.Hilti.C2C.Main.2D.View2dModeType.Bottom'),
                image: getSpriteAsIconStyle('sprite-top-view')
            });
        }

        if (!this.isBeamBottomOverlay) {
            items.push({
                id: 'main-2D-editor-top-view-button',
                value: View2dModeType.Top,
                tooltip: this.translate('Agito.Hilti.C2C.Main.2D.View2dModeType.Top'),
                image: getSpriteAsIconStyle('sprite-top-view')
            });
        }

        items.push({
            id: 'main-2D-editor-side-view-button',
            value: View2dModeType.Side,
            tooltip: this.translate('Agito.Hilti.C2C.Main.2D.View2dModeType.Side'),
            image: getSpriteAsIconStyle('sprite-front-view')
        });

        items.push({
            id: 'main-2D-editor-front-view-button',
            value: View2dModeType.Front,
            tooltip: this.translate('Agito.Hilti.C2C.Main.2D.View2dModeType.Front'),
            image: getSpriteAsIconStyle('sprite-side-view')
        });

        return items;
    }

    private getDisplayOptionsCheckboxSelectedValuesFromUserSettings(): Set<DisplayOption> {
        const displayOptions = this.userSettingsService.settings.applicationModelDisplayOptions;

        let showExistingReinforcementC2C = false;
        if (
            // XXX: Ico: is this even needed?
            this.design.isC2C
            && (this.design.designStandardC2C?.id == DesignStandardC2C.ETAG || this.design.designStandardC2C?.id == DesignStandardC2C.ASBased)
        ) {
            showExistingReinforcementC2C = this.userSettingsService.settings.applicationModelDisplayOptions.existingReinforcement.value ?? false;
        }

        const values = new Set([
            displayOptions.zonesVisible.value ? DisplayOption.Zones : undefined,
            displayOptions.zonesDimensionsVisible.value ? DisplayOption.ZonesDimensions : undefined,
            displayOptions.concreteDimensionsC2C.value ? DisplayOption.ConcreteDimensionsC2C : undefined,
            displayOptions.loadsC2C.value ? DisplayOption.LoadsC2C : undefined,
            displayOptions.transparentBaseMaterialC2C.value ? DisplayOption.BaseMaterialTransparent : undefined,
            showExistingReinforcementC2C ? DisplayOption.ExistingReinforcement : undefined,
            displayOptions.postRebarsNumber.value ? DisplayOption.PostRebarsNumber : undefined,
            displayOptions.newConcreteStructure.value ? DisplayOption.NewConcreteStructure : undefined,
            displayOptions.existingConcreteStructure.value ? DisplayOption.ExistingConcreteStructure : undefined,
            displayOptions.connectorSpacingDimensions.value ? DisplayOption.ConnectorSpacingDimensions : undefined,
            displayOptions.connectorEdgeDistanceDimensions.value ? DisplayOption.ConnectorEdgeDistanceDimensions : undefined,
            displayOptions.postRebarInstallationLengthsAndCovers.value ? DisplayOption.PostRebarInstallationLengthsAndCovers : undefined,
            displayOptions.postRebarDimensions.value ? DisplayOption.PostRebarDimensionsC2C : undefined,
            displayOptions.continuousXDirectionRepresentation.value ? DisplayOption.ContinuousXRepresentation : undefined
        ]);
        values.delete(undefined);

        return values as Set<DisplayOption>;
    }

    private openApprovalC2C(navigationControl: UIPropertyBaseControl) {
        if (navigationControl.UIPropertyId == null) {
            return;
        }

        // Whether the shown approvals are meant for UKTA.
        const isUKTAApproval = navigationControl.UIPropertyId == PropertyMetaDataC2C.Product_C2C_ViewUKTAApproval.id || navigationControl.UIPropertyId == PropertyMetaDataC2C.Product_C2C_ConnectorViewUKTAApproval.id;

        const files = this.design.model[navigationControl.UIPropertyId] as string[];
        const urls = isUKTAApproval ? this.design.model[PropertyMetaDataC2C.Product_C2C_UKTAApprovalUrls.id] as string[] : this.design.model[PropertyMetaDataC2C.Product_C2C_ApprovalUrls.id] as string[];

        if (files.length > 1) {
            this.modalService.openMultipleApprovals(files, urls);
        } else {
            this.design.usageCounterC2C.Approval++;

            const approvalInfo = ApprovalHelper.getApprovalInfo(urls[0], files[0]);
            this.offlineService.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
        }
    }

    private openFullscreenLoader(navigationControl: UIPropertyBaseControl) {
        if (navigationControl.UIPropertyId == null) {
            return;
        }

        this.design.addModelChangeNoCalculation(navigationControl.UIPropertyId, true);

        this.modalService.loadingCustomOpen();
        this.calculationServiceC2C.calculateAsync(this.design, undefined, { suppressLoadingFlag: true })
            .finally(() => {
                this.modalService.loadingCustomClose();
            });
    }

    private onTemplateSaved() {
        this.userService.projectAndDesignView = ProjectAndDesignView.templates;

        this.routingService.navigateToUrl(UrlPath.projectAndDesign);
    }

    private selectTab(tab: string) {
        this.selectTabById(`tab-${tab}`);

        this.hideLeftMenu = false;

        this.resize3dAfterUI();
    }

    private selectTabById(tab: string) {
        this.mainMenuComponent?.selectTab(tab);
    }

    private save2dState(saveProperties: { [uiPropertyId: number]: boolean }, skipSaveModelToProp = false) {
        if (!skipSaveModelToProp) {
            this.save2dModelToProperties(saveProperties);
        }

        this.calculationServiceC2C.calculateAsync(this.design, undefined, undefined);
    }

    private open2d() {
        if (this.view != ViewType.View2d) {
            // Tracking
            this.design.usageCounterC2C.TwoDEditor++;

            this.menu3dSelectedTab = this.selectedMenu.selectedTab;

            /*  If no calculation then immediately update 2d window otherwise after calculation is done. No calculation when:
                    - button "change to 2d" is clicked or
                    - user selects already selected value (toggle button with command change to 2d). "custom anchor layout", "custom stiffeners", ...
            */
            if (!this.design.pendingCalculation) {
                this.update2dView();
            }
            else {
                this.design.one(DesignEvent.afterCalculation, () => {
                    this.update2dView();
                });
            }
        }
    }

    private getDefaultView2dMode() {
        if (this.design.applicationType == ApplicationType.WallReinforcement) {
            return View2dModeType.Front;
        }
        else if (this.design.isC2CHNA) {
            switch (this.design.connectionType) {
                case ConnectionType.ConcreteOverlay:
                case ConnectionType.StructuralJoints:
                    return this.design.isViewRotated ? View2dModeType.Front : View2dModeType.Top;
                case ConnectionType.Splices:
                    return this.design.isViewRotated ? View2dModeType.Top : View2dModeType.Front;
            }
        } else if (this.design.isPirEuOrAus) {
            return this.design.isViewRotated ? View2dModeType.Top : View2dModeType.Front;
        }

        return View2dModeType.Top;
    }

    private update2dView() {
        this.mode2d = Mode2d.Pointer;
        this.view2dMode = this.getDefaultView2dMode();

        this.initMenu2d();

        // update 2d menu
        this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControls(menu, {}));

        this.view = ViewType.View2d;

        const spacing = (this.menu2dService.getControlByName(this.selectedMenu, this.menu2dService.controlName(Controls2dEditorBase.GridSpacing)) as ITextBoxProps).value as string;
        const spacingValue = this.unitService.convertUnitValueToInternalUnitValue(this.unitService.parseUnitValue(spacing, UnitGroup.Length))?.value as number;
        this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl<ITextBoxProps>(menu, this.menu2dService.controlName(Controls2dEditorBase.GridSpacing), { value: this.unitService.formatInternalValueAsDefault(spacingValue, UnitGroup.Length) } as any));
        const showGrid = (this.menu2dService.getControlByName(this.selectedMenu, this.menu2dService.controlName(Controls2dEditorBase.ShowGrid)) as ICheckboxProps).checked;
        this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl<ICheckboxProps>(menu, this.menu2dService.controlName(Controls2dEditorBase.ShowGrid), { checked: showGrid } as any));

        this.glModelComponent.update({
            grid2d: {
                visible: (this.menu2dService.getControlByName(this.selectedMenu, this.menu2dService.controlName(Controls2dEditorBase.ShowGrid)) as ICheckboxProps).checked,
                spacing: spacingValue,
                snapToGrid: false
            }
        });

        this.glModelComponent.resizeNextFrame(1, 2);

        // set gl model view to 2d
        this.glModelComponent.update({
            view: ViewType.View2d,
            editor2d: {
                mode: Mode2d.Pointer
            },
            view2dMode: this.view2dMode
        });

        this.glModelComponent.clearSelected();
        this.glModelComponent.resetCamera();

        setTimeout(() => this.glModelComponent.resizeNextFrame(1, 10));

        // repopulate the display options checkbox items since they change with 2d/3d view
        this.displayOptionsCheckbox.items = this.createDisplayOptionsCheckboxItems();

        this.mode2dToggleButtonGroup = {
            items: this.createMode2dToggleButtonGroupItems()
        };
        this.view2dModeToggleButtonGroup = {
            items: this.createView2dModeToggleButtonGroupItems()
        };
    }

    private dismiss2dInternal() {
        this.setCursor('');
        this.view = ViewType.View3d;

        this.initMenu3d();

        // reselect tab on 3d
        this.mainMenuComponent?.setMainMenuState(menu => ({
            ...menu,
            selectedTab: this.menu3dSelectedTab
        }));

        this.resize3d();

        this.glModelComponent.update({
            view: ViewType.View3d
        });

        this.glModelComponent.clearSelected();
        this.glModelComponent.resetCamera();

        // repopulate the display options checkbox items since they change with 2d/3d view
        this.displayOptionsCheckbox.items = this.createDisplayOptionsCheckboxItems();
    }

    private save2dModelToProperties(saveProperties: { [uiPropertyId: number]: boolean }) {
        const modelC2C = this.glModelComponent?.getModel();

        // pir C2C points
        if (saveProperties[PropertyMetaDataC2C.Product_C2C_PostInstalledRebar_RebarPoints.id] != null) {
            this.design.model[PropertyMetaDataC2C.Product_C2C_PostInstalledRebar_RebarPoints.id] = modelC2C.postInstalledRebar?.points.map(point => ({
                ...point,
                x: point.x - point.offsetX,
                y: point.y - point.offsetY,
                bondCondition: point.bond as number,
                kernelInstallationLength: 0,
            }) as PostInstalledReinforcementPointC2C);
        }

        // longitudinal reinforcement C2C points
        if (saveProperties[PropertyMetaDataC2C.ExistingStructure_C2C_Reinforcement_RebarPoints.id] != null) {
            this.design.model[PropertyMetaDataC2C.ExistingStructure_C2C_Reinforcement_RebarPoints.id] = modelC2C.reinforcement?.longitudinalReinforcementPoints.map(point => ({
                x: point.x,
                y: point.y,
                diameter: point.diameter,
                bondCondition: point.bond as number,
                shapeType: point.shape as number,
                temperature: point.temperature,
                layerId: point.layerId
            }) as LongitudinalReinforcementPointC2C);
        }
    }

    private get2dMenuContext(): IMenu2dContext {
        return {
            glModelComponent: this.glModelComponent,
            currentView: () => this.view,
            currentMenu: () => this.selectedMenu,
            tabSelected: this.tabSelected.bind(this),
            setState: this.mainMenuComponent?.setMainMenuState.bind(this),
            save2dState: this.save2dState.bind(this),
            resize3dAfterUI: this.resize3dAfterUI.bind(this)
        };
    }

    private initMenu2d() {
        this.mainMenuComponent?.initMenu2d(() => this.menu2dService.createMenu(this.get2dMenuContext(), {
            ['OpenApprovalC2C']: this.openApprovalC2C.bind(this),
            ['OpenDesignSettingsPopup']: () => this.openDesignSettings(),
            ['OpenDefineOtherRebarParameters']: () => {
                this.design.usageCounterC2C.DefineOtherRebarParameters++;
                this.openDesignSettings([AfterOpenInstruction.ScrollToRebarCalculationSection]);
            }
        } as unknown as Record<MainMenuCommands, (navigationControl: BaseControl) => void>));
    }

    private initMenu3d() {
        this.mainMenuComponent?.initMenu3d(this.design, this.tabSelected, {
            ['OpenImportLoadsPopup']: () => this.modalService.openImportLoads(),
            ['Open2dEditor']: () => { setTimeout(this.open2d.bind(this)); },
            ['OpenFullscreenLoader']: () => this.openFullscreenLoader.bind(this),
            ['OpenNotOwnedLearnMore']: this.openUpgradePE.bind(this),
            ['OpenApprovalC2C']: this.openApprovalC2C.bind(this),
            ['OpenAlphaSustained']: () => this.modalService.openInfoDialogC2C('OpenAlphaSustained', 'adaptive'),
            ['OpenContactSurfaceConditionPopup']: () => this.modalService.openInfoDialogC2C('OpenContactSurfaceConditionPopup'),
            ['OpenEpoxyCoatedReinforcementPopup']: () => this.modalService.openInfoDialogC2C('OpenEpoxyCoatedReinforcementPopup'),
            ['OpenLocationFactorPopup']: () => this.modalService.openInfoDialogC2C('OpenLocationFactorPopup'),
            ['OpenDetailedDefinitionPopup']: () => this.modalService.openInfoDialogC2C('OpenDetailedDefinitionPopup',  'adaptive'),
            ['OpenOptimizedValuesPopup']: () => this.modalService.openInfoDialogC2C('OpenOptimizedValuesPopup',  'lg'),
            ['OpenUseFullCrossSectionPopup']: () => this.modalService.openInfoDialogC2C('OpenUseFullCrossSectionPopup',  'lg'),
            ['OpenRebarEpoxyCoatedPopup']: () => this.modalService.openInfoDialogC2C('OpenRebarEpoxyCoatedPopup'),
            ['OpenSpliceToExistingReinforcementPopup']: () => this.modalService.openInfoDialogC2C('OpenSpliceToExistingReinforcementPopup'),
            ['OpenOverstrengthPopup']: () => this.modalService.openInfoDialogC2C('OpenOverstrengthPopup'),
            ['OpenPhiTensionPopup']: () => this.modalService.openInfoDialogC2C('OpenPhiTensionPopup'),
            ['OpenBondBreakoutPopup']: () => this.modalService.openInfoDialogC2C('OpenBondBreakoutPopup'),
            ['OpenIsYieldDesignPopup']: () => this.modalService.openInfoDialogC2C('OpenIsYieldDesignPopup', 'lg'),
            ['OpenPermanentNetCompressionPopup']: () => this.modalService.openInfoDialogC2C('OpenPermanentNetCompressionPopup'),
            ['OpenRatioBInfoBox']: () => this.modalService.openInfoDialogC2C('OpenRatioBInfoBox'),
            ['OpenDesignSettingsPopup']: () => this.openDesignSettings(),
            ['OpenTransverseReinforcementDescriptionPopup']: () => this.modalService.openInfoDialogC2C('OpenTransverseReinforcementDefineInfoPopup'),
            ['OpenShearDesignOptionDeltaFsPopup']: () => this.modalService.openInfoDialogC2C('OpenShearDesignOptionDeltaFsPopup'),
            ['OpenSurfaceReinforcementInfoPopup']: () => this.modalService.openInfoDialogC2C('OpenSurfaceReinforcementInfoPopup'),
            ['OpenShearDesignOptionInterfacePopup']: () => this.modalService.openInfoDialogC2C('OpenShearDesignOptionInterfacePopup'),
            ['OpenConcreteDensityPopup']: () => this.modalService.openInfoDialogC2C('OpenConcreteDensityPopup'),
            ['OpenTensileStrengthPopup']: () => this.modalService.openInfoDialogC2C('OpenTensileStrengthPopup'),
            ['OpenExtensionAtSupportPopup']: () => this.modalService.openInfoDialogC2C('OpenExtensionAtSupportPopup'),
            ['OpenContinuousInXPopup']: () => this.modalService.openInfoDialogC2C('OpenContinuousInXPopup'),
            ['OpenCrackedConcretePopup']: () => this.modalService.openInfoDialogC2C('OpenCrackedConcretePopup'),
            ['OpenDesignForYieldPopup']: () => this.modalService.openInfoDialogC2C('OpenDesignForYieldPopup'),
            ['OpenMaxAreaReinforcementPopup']: () => this.modalService.openInfoDialogC2C('OpenMaxAreaReinforcementPopup'),
            ['OpenSurfaceRoughnessPopup']: () => this.modalService.openInfoDialogC2C('OpenSurfaceRoughnessPopup'),
            ['OpenMinAreaReinforcementItemsC2C']: () => this.modalService.openInfoDialogC2C('OpenMinAreaReinforcementItemsC2C',  'lg'),
            ['OpenCompressionConfinementPopup']: () => this.modalService.openInfoDialogC2C('OpenCompressionConfinementPopup'),
            ['OpenAdditionalOffsetDistancePopup']: () => this.modalService.openInfoDialogC2C('OpenAdditionalOffsetDistancePopup',  'lg'),
            ['OpenFireResistanceDurationPopup']: () => this.modalService.openInfoDialogC2C('OpenFireResistanceDurationPopup'),
            ['OpenDrillingAidPopup']: () => this.modalService.openInfoDialogC2C('OpenDrillingAidPopup'),
            ['OpenTransverseReinforcementUnfavorableTolerancePopup']: () => this.modalService.openInfoDialogC2C('OpenTransverseReinforcementUnfavorableTolerancePopup',  'lg'),
            ['OpenRebarPositionPopup']: () => this.modalService.openInfoDialogC2C('OpenRebarPositionPopup'),
            ['OpenSurfaceTreatmentPopup']: () => this.modalService.openInfoDialogC2C('OpenSurfaceTreatmentPopup'),
            ['OpenSpliceClassesPopup']: () => this.modalService.openInfoDialogC2C('OpenSpliceClassesPopup'),
            ['OpenIncludeReinforcementCompressionPopup']: () => this.modalService.openInfoDialogC2C('OpenIncludeReinforcementCompressionPopup',  'lg'),
            ['OpenDesignMethodGroupsSelectionPopup']: () => this.modalService.openInfoDialogC2C('OpenDesignMethodGroupsSelectionPopup',  'lg'),
            ['OpenRebarSpacingPopup']: () => this.modalService.openInfoDialogC2C('OpenRebarSpacingPopup'),
            ['OpenDefineOtherRebarParameters']: () => {
                this.design.usageCounterC2C.DefineOtherRebarParameters++;
                this.openDesignSettings([AfterOpenInstruction.ScrollToRebarCalculationSection]);
            },
            ['OpenDefinitionOfMinMaxReinforcement']: () => {
                this.design.usageCounterC2C.DefinitionOfMinMaxReinforcement++;
                this.openDesignSettings([AfterOpenInstruction.ScrollToRebarCalculationSection]);
            },
            ['OpenUseHiltiDesign']: () => this.modalService.openInfoDialogC2C('OpenUseHiltiDesign',  'md', true),
            ['OpenDistanceCipPir']: () => this.modalService.openInfoDialogC2C('OpenDistanceCipPir'),
            ['OpenAnchorTheoryPopup']: () => this.modalService.openInfoDialogC2C('OpenAnchorTheoryPopup',  'lg'),
            ['OpenDesignMethodGroupsOverlayPopup']: () => this.modalService.openInfoDialogC2C('OpenDesignMethodGroupsOverlayPopup', 'md'),
            ['OpenHiltiMethodRebarDesignPopup']: () => this.modalService.openInfoDialogC2C('OpenHiltiMethodRebarDesignPopup', 'md'),
            ['OpenAnchoringBarYieldPopup']: () => this.modalService.openInfoDialogC2C('OpenAnchoringBarYieldPopup', 'md'),
            ['OpenAnchoringExternalLoadsPopup']: () => this.modalService.openInfoDialogC2C('OpenAnchoringExternalLoadsPopup', 'md'),
            ['OpenAllowResearchBasedPopup']: () => this.modalService.openInfoDialogC2C('OpenAllowResearchBasedPopup', 'md'),
            ['OpenSpliceClassesAlternativePopup']: () => this.modalService.openInfoDialogC2C('OpenSpliceClassesAlternativePopup', 'md'),
            ['OpenConnectorPositionOverlayPopup']: () => this.modalService.openInfoDialogC2C('OpenConnectorPositionOverlayPopup'),
            ['OpenNetCompressionPopup']: () => this.modalService.openInfoDialogC2C('OpenNetCompressionPopup', 'md'),
            ['OpenLapSpliceLengthPopup']: () => this.modalService.openInfoDialogC2C('OpenLapSpliceLengthPopup', 'md'),
            ['OpenProductTransverseReinforcementPopup']: () => this.modalService.openInfoDialogC2C('OpenProductTransverseReinforcementPopup'),
        } as unknown as Record<MainMenuCommands, (navigationControl?: BaseControl) => void>);
    }

    private tabSelected() {
        this.hideLeftMenu = false;

        this.resize3dAfterUI();
    }

    private initZoneAnalysisDropdown() {
        this.zoneAnalysisInitialized = true;
    }

    private initRightSideC2C() {
        this.rightSideLoaded = true;

        this.exportReportSupportMethods = {
            createGlModelScreenshot2D: (extraInfo: IScreenShotSettingsC2C, view2dMode: View2dModeType) => this.glModelComponent.createDesignScreenshot2D(extraInfo, view2dMode),
            createGlModelScreenshot: (extraInfo: IScreenShotSettingsC2C) => this.glModelComponent.createDesignScreenshot(extraInfo),
            createZoneDiagramScreenshot: () => this.showZoneAnalysis && this.zoneAnalysisInput?.createScreenshot != null ?
                this.zoneAnalysisInput.createScreenshot() :
                Promise.resolve('')
        };
    }

    private resize3dAfterUI() {
        // the UI might update later so we resize it twice
        this.resize3d();

        setTimeout(() => {
            this.resize3d();
        });
    }

    /* GL MODEL */
    private initGLModel() {
        return new Promise<void>(resolve => {
            const model: IModel = {
                tooltips: true,
            };

            this.onBeforeCalculate = this.onBeforeCalculate.bind(this);
            this.onStateChanged = this.onStateChanged.bind(this);
            this.onCalculate = this.onCalculate.bind(this);

            this.design.onBeforeCalculate(this.onBeforeCalculate);
            this.design.onCalculate(this.onCalculate);
            this.design.onStateChanged(this.onStateChanged);

            this.initGLModelC2C(model, resolve);
        });
    }

    private initGLModelC2C(model: IModel, resolve: (value: void | PromiseLike<void>) => void): void {
        const modelC2C: IModelC2C = {
            ...model,
            force: {
                visible: this.userSettingsService.settings.applicationModelDisplayOptions.loadsC2C.value ?? undefined,
            },
            moment: {
                visible: this.userSettingsService.settings.applicationModelDisplayOptions.loadsC2C.value ?? undefined,
            },
            tooltips: true,
            visibilityPropertiesC2C: {
                zones: this.userSettingsService.settings.applicationModelDisplayOptions.zonesVisible.value ?? undefined,
                loads: this.userSettingsService.settings.applicationModelDisplayOptions.loadsC2C.value ?? undefined,
                zonesDimensions: this.userSettingsService.settings.applicationModelDisplayOptions.zonesDimensionsVisible.value ?? undefined,
                baseMaterialsDimensions: this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensionsC2C.value ?? undefined,
                baseMaterialTransparent: this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterialC2C.value ?? undefined,
                existingReinforcement: this.userSettingsService.settings.applicationModelDisplayOptions.existingReinforcement.value ?? undefined,
                newConcreteStructure: this.userSettingsService.settings.applicationModelDisplayOptions.newConcreteStructure.value ?? undefined,
                existingConcreteStructure: this.userSettingsService.settings.applicationModelDisplayOptions.existingConcreteStructure.value ?? undefined,
                connectorSpacingDimensions: this.userSettingsService.settings.applicationModelDisplayOptions.connectorSpacingDimensions.value ?? undefined,
                connectorEdgeDistanceDimensions: this.userSettingsService.settings.applicationModelDisplayOptions.connectorEdgeDistanceDimensions.value ?? undefined,
                connectors: true,
                showPostRebarsNumber: this.userSettingsService.settings.applicationModelDisplayOptions.postRebarsNumber.value ?? undefined,
                showEurocodeModels: this.design.designStandardC2C?.id == DesignStandardC2C.ETAG,
                showNewConcreteStructureLines: this.design.designStandardC2C?.id == DesignStandardC2C.ETAG,
                showPostRebarInstallationLengthsAndCovers: this.userSettingsService.settings.applicationModelDisplayOptions.postRebarInstallationLengthsAndCovers.value ?? undefined,
                postRebarDimensions: this.userSettingsService.settings.applicationModelDisplayOptions.postRebarDimensions.value ?? undefined,
                showAustralianModels: this.design.designStandardC2C?.id == DesignStandardC2C.ASBased
            },
            baseMaterialsC2C: {
                applicationType: this.design.applicationType as number,
                connectionType: this.design.connectionType as number,
                existingStructure: {},
                newStructure: {},
                supportStructure: {},
            } as IBaseMaterialsC2CModel,
            zoneModel: {} as IZonesC2CModel,
        };

        this.onBeforeCalculate = this.onBeforeCalculate.bind(this);
        this.onStateChanged = this.onStateChanged.bind(this);
        this.onCalculate = this.onCalculate.bind(this);

        this.design.onBeforeCalculate(this.onBeforeCalculate);
        this.design.onCalculate(this.onCalculate);
        this.design.onStateChanged(this.onStateChanged);

        this.glModel = {
            continuousRender: this.glDebug,
            model: modelC2C,
            onFontsLoaded: () => {
                this.updateGLModel(this.design, null as any, Update.ServerAndClient, undefined, false, true);

                setTimeout(() => {
                    this.glModelComponent.update({ hidden: false }, undefined, true);
                    resolve();
                }, 100);
            },
            onZoom: (zoom) => {
                this.modelViewZoom = Math.round(100 - zoom);
                this.changeDetector.detectChanges();
            },
            onSelectTab: (tab) => {
                this.selectTab(tab);
            },
            onPositionsChanged: (components) => {
                const updateObj: { [uiProperty: number]: boolean } = {};

                if (components[PostRebar2d.componentId] != null) {
                    this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl(menu, this.menu2dService.controlName(Controls2dEditorC2C.PostInstalledRebar), { activeValue: null } as any));

                    const menu2dContext = this.get2dMenuContext();
                    this.menu2dService.updatePointsTable(menu2dContext, AdvancedPointsTableType.PostInstallRebar);

                    updateObj[PropertyMetaDataC2C.Product_C2C_PostInstalledRebar_RebarPoints.id] = true;
                }

                this.save2dState(updateObj);
            },
            onDraggingSelectionChanged: () => {
                // do nothing
            },
            onRebarClicked: (locked) => {
                if (this.design.isPirEu && locked && this.design.designData.projectDesignC2C.product.calculationMode != CalculationMode.Verification) {
                    this.startUnlockRebarTour();
                }
            }
        };
    }

    private startUnlockRebarTour() {
        const selectedTab = this.selectedMenu.selectedTab.substring(4);
        if (
            selectedTab != MenuTabs2DPir.Pir
            && selectedTab != MenuTabs2DPir.Coordinates2D
        ) {
            this.selectTab(MenuTabs2DPir.Coordinates2D);
        }

        setTimeout(() => {
            this.tourService.unlockRebarTour();
        });
    }

    private createDesignScreenshot() {
        if (this.design == null) {
            return;
        }

        const isTemplate = this.design.isTemplate;
        const isCreateTemplate = this.userService.isCreateTemplate;
        const id = (isTemplate ? this.design.templateId : this.design.id) as string;

        const [imgHeight , imgWidth] = this.isNewHomePage ? [120, 190] : [145, 145];

        this.glModelComponent.createDesignScreenshot({ isThumbnail: true, imgHeight: imgHeight, imgWidth: imgWidth, zoomed: false, preview: false, isHomepageThumbnail: this.isNewHomePage } as IScreenShotSettingsC2C)
            .then(img => {
                if (isTemplate) {
                    this.designTemplateService.updateDesignThumbnailImage(id, img, false);
                }
                else {
                    this.documentService.updateDesignThumbnailImage(id, img, false).then(async () => {
                        if (isCreateTemplate) {
                            await this.createNewTemplate();
                        }
                    });
                }
            });
    }

    private async createNewTemplate() {
        //save new  template
        const template = {
            designTemplateDocument: this.getDesignTemplateDocumentC2C(),
            thumbnailId: this.design.id,
        };
        template.designTemplateDocument.templateName = this.design.templateName;
        template.designTemplateDocument.templateFolderId = this.design.templateFolderId;
        await this.designTemplateService.create(template.designTemplateDocument, template.thumbnailId).then((response) => {
            //enable editing of template file
            this.userService.design.templateId = response;
            this.userService.design.isTemplate = true;
            this.userService.setIsCreateTemplate(false);
        });
    }

    private onBeforeCalculate(design: Design, changes: Change[]) {
        if (changes != null && changes.length > 0) {
            this.updateGLModel(design, changes, Update.Client, undefined, true);
        }
    }

    private onStateChanged(design: Design, state: IDesignState, oldState: IDesignState, stateChange: StateChange) {
        if (stateChange == StateChange.server) {
            this.callAllUpdateMethods(oldState, state);
        }
        else {
            this.callOtherStateChanges(state, oldState);
        }

        this.displayOptionsCheckbox.items = this.createDisplayOptionsCheckboxItems();

        this.resize3dAfterUI();
        this.setNotificationComponentInputs();
    }

    private callOtherStateChanges(state: IDesignState, oldState: IDesignState) {
        if (state === oldState) {
            this.updateGLModel(this.design, null as any, Update.ServerAndClient);
        }
        else {
            const changes = Object.values(this.changesService.getShallowChanges(oldState.model, state.model, true));

            if (changes != null && changes.length > 0) {
                this.updateGLModel(this.design, changes, Update.ServerAndClient, undefined, false, true);
            }
        }
    }

    private callAllUpdateMethods(oldState: IDesignState, state: IDesignState) {
        this.updateGLModel(this.design, null as any, Update.Server);

        if (this.isPirEu) {
            setTimeout(() => {
                const changes = Object.values(this.changesService.getShallowChanges(oldState.model, state.model, true));
                const existingReinforcementChange = changes?.find(x => x.name == (PropertyMetaDataC2C.General_C2C_ConnectionType.id.toString()) || x.name == (PropertyMetaDataC2C.General_C2C_ApplicationType.id.toString()));

                if (existingReinforcementChange != null) {
                    this.resetCamera();
                }
            });
        }
    }

    private onCalculate(design: Design, changes: Change[]) {
        this.updateGLModel(design, changes, Update.Client, undefined, false, true);
    }

    private updateGLModel(design: Design, changes: Change[], update: Update, model?: IModelC2C, beforeCalculate = false, updateDesignScreenshot = false) {
        this.glModelComponent?.propertyValueChanged(changes, design, update, model);

        // updateGLModel is called multiple times so we only update the screenshot when update != Controls.GLUpdate.Update.server
        if (!beforeCalculate && update != Update.Server && updateDesignScreenshot) {
            setTimeout(() => {
                this.createDesignScreenshot();
            });
        }
    }

    private onKeyDown(event: KeyboardEvent) {
        // undo
        if (event.ctrlKey === true && (event.key === 'Z' || event.key === 'z')) {
            event.stopPropagation();
            event.preventDefault();

            this.undo();
        }

        // redo
        if (event.ctrlKey === true && (event.key === 'Y' || event.key === 'y')) {
            event.stopPropagation();
            event.preventDefault();

            this.redo();
        }

        // open import design popup
        if (event.ctrlKey === true && (event.key === 'I' || event.key === 'i')) {
            event.stopPropagation();
            event.preventDefault();

            this.designSectionComponentRef.nativeElement.openFile();
        }
    }

    private setNotificationComponentInputs() {
        this.notificationComponentInputs = {
            isVisible: () => {
                return this.design && (this.hasScopeChecksC2C || this.isLongCalculationVisibleC2C);
            },
            isInfoMessageVisible: () => {
                return true;
            },
            notifications: [
                {
                    condition: () => { return this.isLongCalculationVisibleC2C; },
                    text: this.translate('Agito.Hilti.C2C.Notification.Calculate.Message'),
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.C2C.Notification.Calculate'),
                        condition: () => { return true; },
                        click: () => {  this.ngZone.run(() => this.runAdvancedCalculationC2C()); },
                        disableTooltip: () => { return true; },
                        disabled: () => false
                    },
                    notificationLocation: NotificationLocation.Start
                },
                {
                    condition: () => { return this.isHnaFire; },
                    text: this.translate('Agito.Hilti.Profis3.ScopeCheck.SC_Fire_HNA_Tech_Manual'),
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.ScopeCheck.SC_Fire_HNA_Tech_Manual.Button'),
                        condition: () => { return true; },
                        click: () => {
                            this.openHnaTechManualLink();
                        },
                        disableTooltip: () => { return true; },
                        disabled: () => { return false; }
                    },
                    notificationLocation: NotificationLocation.End
                }
            ],
            scopeChecks: this.notificationScopeChecksC2C
        } as INotificationsComponentInput;
    }

    private onDesignStateChangedUpdateValues() {
        this.mode2dToggleButtonGroup = {
            items: this.createMode2dToggleButtonGroupItems()
        };
    }
}
