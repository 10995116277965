import {
    DesignStandard as DesignStandardC2C
} from '../entities/code-lists/design-standard';
import {
    ConnectionType,
    DesignMethod,
    DesignStandard as DesignStandardEnumC2C,
    DesignMethodGroup as DesignMethodGroupEnumC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { FeatureFlagTypes } from '../generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import {
    DesignMethodGroup as DesignMethodGroupC2C
} from '../entities/code-lists/design-method-group';
import { DesignC2C as Design } from '../entities/design-c2c';
import clone from 'lodash-es/clone';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { FeatureVisibilityServiceBase } from '@profis-engineering/pe-ui-common/services/feature-visibility.common';

export function getUKDesignStandardKey(regionId: number, designStandard: DesignStandardC2C, featureFlagUKTA = false) {
    if (designStandard.id == DesignStandardEnumC2C.ETAG) {
        if (featureFlagUKTA && regionId == 5) {
            designStandard.nameResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode.Uk';
            designStandard.descriptionResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode.Uk.Description';
        }
        else {
            designStandard.nameResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode';
            designStandard.descriptionResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode.Description';
        }
    }

    return designStandard;
}

export function getModifiedDesignMethodKeyByDesign(design: Design, featureVisibilityService: FeatureVisibilityServiceBase<string>) {
    return getModifiedDesignMethodKey(design.region.id ?? 0, design.connectionType, design.designStandardC2C?.id ?? 0, design.designMethodGroup as DesignMethodGroupC2C , featureVisibilityService);
}

export function getModifiedDesignMethodKey(regionId: number, connectionType: ConnectionType, designStandard: DesignStandardEnumC2C, designMethod: DesignMethodGroupC2C, featureVisibilityService: FeatureVisibilityServiceBase<string>) {
    const result = clone(designMethod);

    if (result.id == DesignMethodGroupEnumC2C.TR066ETA && regionId == KnownRegion.UnitedKingdom && featureVisibilityService.isFeatureEnabled('C2C_UKTA')) {
        result.nameResourceKey = 'Agito.Hilti.C2C.CodeList.DesignMethodGroupEntity.TR066.Uk';
        result.displayKey = 'TR066.Uk';
    }
    else if (connectionType == ConnectionType.ConcreteOverlay && designStandard == DesignStandardEnumC2C.ETAG && result.id == DesignMethodGroupEnumC2C.HiltiRebarDesign ) {
        result.nameResourceKey = `${result.nameResourceKey}OverlayEu`;
        result.displayKey = `${result.displayKey}OverlayEu`;
    }

    return result;
}

export function isHnaAnchoringBarYieldMethod(design: Design | undefined): boolean {
    if (!design) {
        return false;
    }

    return (
        design.connectionType === ConnectionType.StructuralJoints ||
        design.connectionType === ConnectionType.ConcreteOverlay
    ) &&
    design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods) &&
    design.designData.projectDesignC2C.options.selectedDesignMethod == DesignMethod.AnchoringBarYield &&
    design.isC2CHNA;
}
